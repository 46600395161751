import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../api/api";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";

const JoinNowForm = () => {
  const [buttonloader, setButtonloader] = useState(false);
  const [countrylist, setCountry] = useState([]);

  useEffect(() => {
    const getCountry = async () => {
      try {
        const response = await axiosInstance.get("/country");
        console.log(response?.data?.data);
        setCountry(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };

    getCountry();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileNo: "",
      companyName: "",
      countryId: "120000",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(3, "Name must be at least 3 characters")
        .max(100, "Name cannot exceed 100 characters"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email id"
        )
        .min(2, "Email must be at least 3 characters")
        .max(255, "Name cannot exceed 100 characters"),
      mobileNo: Yup.string()
        .required("Mobile no is required")
        .matches(
          /^(?!(0{10}))[^1-5]\d{9}$/,
          "Please enter a valid mobile number"
        ),
      companyName: Yup.string()
        .required("Company name is required")
        .max(255, "Company name cannot exceed 255 characters")
        .matches(/^[\w\s/.-]+$/, "Invalid characters"),
      countryId: Yup.string()
        .notOneOf(["120000"], "Country is required")
        .required("Country is required"),
      message: Yup.string()
        .required("Message is required")
        .max(3000, "Message cannot exceed 3000 characters"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      const bodyData = {
        name: values?.name,
        mobileNo: values?.mobileNo,
        email: values?.email,
        companyName: values?.companyName,
        countryId: values?.countryId,
        message: values?.message,
      };

      setButtonloader(true);

      try {
        const res = await axiosInstance.post("/enquiry/create", bodyData);

        console.log(res);
        if (res?.status === 201) {
          setButtonloader(false);
          // ToastServices.SucessToast("Enquiry submitted successfully");
          Swal.fire({
            title: "Success!",
            text: "Your request has been submitted successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
          formik.resetForm();
        } else if (res?.data?.status === false) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
        } else if (res?.statusCode === 429) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
        } else if (res?.statusCode === 500) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
        }
        if (res?.statusCode === 404) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
        }
      } catch (err) {
        console.log(err, "something went wrong");
        setButtonloader(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },
  });
  // const handleKeyDown = (event) => {

  //   if (event.keyCode === 38 || event.keyCode === 40 ) {

  //     event.preventDefault();
  //   }
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   Object.keys(formik.values).forEach(fieldName => {
  //     formik.setFieldTouched(fieldName, true);
  //   });

  //   formik.validateForm().then(errors => {
  //     const hasErrors = Object.keys(errors).length > 0;
  //     if (hasErrors) {

  //       Swal.fire({
  //         title: 'Error!',
  //         text: 'Please fill the mandatory fields',
  //         icon: 'error',
  //         confirmButtonText: 'OK'
  //       });
  //     } else {
  //       formik.handleSubmit(event);
  //     }
  //   });

  // };

const handlekeydown = (evt) =>{
["e", "E", "+", "-", ".", "ctrlKey", "vKey"].includes(evt.key) &&evt.preventDefault();
if (evt.keyCode === 38 || evt.keyCode === 40 || evt.keyCode === 17 || evt.keyCode === 86) {
  evt.preventDefault();
}

}

  return (
    <div>
      <Container>
        <div className="join-now-card">
          <Form onSubmit={formik.handleSubmit} autocomplete="off">
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="name-input">
                  <Form.Label>
                    Full Name <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <small className="text-danger d-block mt-1 ms-1">
                      {formik.errors.name}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-30" controlId="email-input">
                  <Form.Label>
                    Email Id <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Id"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <small className="text-danger d-block mt-1 ms-1">
                      {formik.errors.email}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-30" controlId="mobile-input">
                  <Form.Label>
                    Mobile No <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    onKeyDown={(evt) =>handlekeydown(evt)}
                    type="number"
                    placeholder="Enter Mobile No"
                    {...formik.getFieldProps("mobileNo")}
                  />
                  {formik.touched.mobileNo && formik.errors.mobileNo ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.mobileNo}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-30" controlId="company-input">
                  <Form.Label>
                    Company Name <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    {...formik.getFieldProps("companyName")}
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.companyName}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-30" controlId="company-input">
                  <Form.Label>
                    Country <span className="required">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="select"
                    {...formik.getFieldProps("countryId")}
                    defaultValue="120000"
                  >
                    <option value="120000" disabled selected>
                      Select Country
                    </option>
                    {countrylist
                      .map((item, index) => (
                        <option key={index} value={item?.countryId}>
                          {item?.name}
                        </option>
                      ))}
                  </Form.Select>
                  {formik.touched.countryId && formik.errors.countryId ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.countryId}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group className="mb-30" controlId="name-input">
                  <Form.Label>
                    Message <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="Add Your Message Here..."
                    {...formik.getFieldProps("message")}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.message}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Col lg={{ span: 2, offset: 5 }}>
              <div className="text-center">
                <button type="submit" className="primary-btn mt-5 w-100 submit">
                  {buttonloader === true ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                    />
                  ) : null}
                  <span> {buttonloader === true ? null : "Submit"} </span>
                </button>
              </div>
            </Col>
          </Form>
        </div>
      </Container>
      
    </div>
  );
};

export default JoinNowForm;
