import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { OurPresenceImg } from "../../assets/images";

const OurPresence = () => {
  return (
    <div className="nimalaan-main-section mt-75">
      <h2 className="nimalaan-section-title" data-aos="fade-up" data-aos-duration="300">Our presence is in india</h2>
      <div>
        <Container>
          <Row data-aos="fade-down" data-aos-duration="1000">
            <Col lg={{ span: 8, offset: 2 }}>
              <p className="mt-1 text-center text-information">
                With years of experience in the renewable energy industry, our
                team of experts is equipped to handle the most complex
                projects with ease. From site assessment and resource
                analysis to permitting, financing, and construction, we offer
                a full range of services to help our clients achieve
                their renewable energy goals.
              </p>
            </Col>
          </Row>
          <Row className="mt-45" data-aos="fade-down" data-aos-duration="1000">
            <Col xs={12} md={12} lg={12}>
              <img
                className="img-fluid mt-10"
                src={OurPresenceImg}
                alt="our-presence-is-in-india"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OurPresence;
