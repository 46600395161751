import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const EventDetailBanner = ({ bannerImage }) => {
    const imageRef = useRef(null);
    const [width, setWidth] = useState();
  
    const updateWidth = () => {
      if (imageRef.current) {
        setWidth(imageRef.current.offsetWidth);
      }
    };
    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
          window.removeEventListener('resize', updateWidth);
        };
      }, []);

    return (
        <div>
            <Container>
                <Row>
                    <Col ref={imageRef} className='event-detail-banner' xs={12}>
                        <img width={'100%'} style={{height: width*0.25}} className='event_banner' src={bannerImage} alt="Event-banner"/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default EventDetailBanner