import { useEffect } from "react";

const HeaderSeo = ({ data }) => {
  useEffect(() => {
    if (!data) {
      return;
    }

    document.title = data?.title || "Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future";
    const metaKeywords = document.querySelector('meta[name="keywords"]');
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaKeywords) {
      metaKeywords.setAttribute("content", data?.keywords || "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions");
    }
    if (metaDescription) {
      metaDescription.setAttribute("content", data?.description || "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions");
    }

  }, [data]); 
  return null;
};

export default HeaderSeo;
