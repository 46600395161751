import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: ' https://api.nimalaanenergies.com', 
   
  headers: {
    'Content-Type': 'application/json', 
   
  },
});

export default axiosInstance;