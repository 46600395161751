import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Banner_2 = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div className="type_2">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="nimalaan_banner_inner_section">
            <div className="mt-130">
              <h1 data-aos="fade-up" data-aos-duration="1000">{data?.title}</h1>
              {data.type === 2 && <p data-aos="fade-up" data-aos-duration="1000">{data?.content}</p>}
              {data.type === 3 && (
                <>
                  {data.content.map((para, index) => (
                    <div key={index}>
                      <p data-aos="fade-up" data-aos-duration="1000">{para}</p>
                    </div>
                  ))}
                </>
              )}
              {data?.joinnow === true && (
                <button className="primary-btn mt-4" data-aos="fade-up" data-aos-duration="1000" onClick={() => {
                  navigate("/join-now");}}>Join now</button>
              )}
            </div>
            </div>
          </Col>

          <Col className="text-center" lg={6}>
            <img
              className="nimaalan-powering-banner-image_type_2_image img-fluid w" data-aos="zoom-in"
              src={data?.image}
              alt={data?.alt}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Banner_2;
