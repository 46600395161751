import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'

const CarrierBanner = (props) => {
  const navigate = useNavigate()
  const applyJob = (props) => {
    navigate(`/careers/apply-now/${props?.id}`);
  };

  return (
    <div className='carrier_banner' style={{ height :` ${props.Height}px`}}>
        <Container fluid>
          <Container >
            <Row>
                <Col lg={{span:12 }}>
                  <div className='text-center carrier_banner_content' data-aos="fade-up" data-aos-duration="1000">
                         <p >Nimaalan |  <span> {props.JobShift}</span></p>
                         <h1>{props.JobPosition}</h1>
                         {
                           props.btn && (<button className='job-view-btn' onClick={() => applyJob(props)}>Apply Now</button>)
                         }
                  </div>
                </Col>
            </Row>
          </Container>
        </Container>
    </div>
  )
}

export default CarrierBanner