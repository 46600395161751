export const PROJECTMANGEMENT = () => {
  return (
    <svg
      suppressHydrationWarning={true}
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      viewBox="0 0 104 104"
      fill="none"
    >
      <g clip-path="url(#clip0_36_4435)">
        <path
          d="M51.9999 75.9912C75.381 75.9912 94.3352 67.2435 94.3352 56.4527C94.3352 45.6618 75.381 36.9141 51.9999 36.9141C28.6187 36.9141 9.66455 45.6618 9.66455 56.4527C9.66455 67.2435 28.6187 75.9912 51.9999 75.9912Z"
          fill="#E6FF91"
        />
        <path
          d="M51.9999 64.5953C65.4888 64.5953 76.4237 59.8847 76.4237 54.074C76.4237 48.2633 65.4888 43.5527 51.9999 43.5527C38.5111 43.5527 27.5762 48.2633 27.5762 54.074C27.5762 59.8847 38.5111 64.5953 51.9999 64.5953Z"
          fill="#BEE86E"
        />
        <path
          d="M60.444 44.5974C60.444 39.5207 56.3138 35.3906 51.2371 35.3906C48.8705 35.3906 46.7099 36.2888 45.0771 37.7615V34.8277C45.0771 31.4311 47.8405 28.6678 51.2371 28.6678C52.6281 28.6678 53.9403 29.1199 55.0323 29.9753C55.6947 30.4943 56.6524 30.3777 57.171 29.7155C57.69 29.0531 57.5736 28.0956 56.9112 27.5768C55.2779 26.2973 53.3159 25.6211 51.2371 25.6211C46.1604 25.6211 42.0303 29.7512 42.0303 34.8279V45.5135C42.0303 50.5902 46.1604 54.7204 51.2371 54.7204C56.3138 54.7204 60.444 50.5902 60.444 45.5135V44.5974ZM57.3971 45.5133C57.3971 48.91 54.6338 51.6733 51.2371 51.6733C47.8405 51.6733 45.0771 48.91 45.0771 45.5133V44.5974C45.0771 41.2008 47.8405 38.4375 51.2371 38.4375C54.6338 38.4375 57.3971 41.2008 57.3971 44.5974V45.5133Z"
          fill="black"
        />
        <path
          d="M29.204 51.6737C27.1011 51.6737 25.1702 50.7072 23.9061 49.0223C23.4012 48.3494 22.4465 48.2131 21.7733 48.7178C21.1004 49.2228 20.9641 50.1777 21.4688 50.8506C23.3138 53.3099 26.1332 54.7204 29.2043 54.7204C34.5334 54.7204 38.8691 50.3847 38.8691 45.0555C38.8691 41.3722 36.7979 38.1635 33.7591 36.5328C34.9045 35.3743 35.6126 33.7831 35.6126 32.0293C35.6126 28.4957 32.738 25.6211 29.2045 25.6211C27.625 25.6211 26.1076 26.2008 24.9315 27.2534C24.3047 27.8146 24.2515 28.7777 24.8127 29.4045C25.3739 30.0313 26.3367 30.0848 26.9638 29.5235C27.5801 28.9718 28.3759 28.668 29.2045 28.668C31.058 28.668 32.5658 30.1758 32.5658 32.0293C32.5658 33.8828 31.058 35.3908 29.2045 35.3908C28.3631 35.3908 27.681 36.0727 27.681 36.9142C27.681 37.7556 28.3631 38.4377 29.2045 38.4377C32.8536 38.4377 35.8225 41.4063 35.8225 45.0555C35.8221 48.7048 32.8532 51.6737 29.204 51.6737Z"
          fill="black"
        />
        <path
          d="M81.2035 34.4719C81.2035 29.5916 77.233 25.6211 72.3527 25.6211C67.4724 25.6211 63.502 29.5914 63.502 34.4719V45.8698C63.502 50.7501 67.4724 54.7206 72.3527 54.7206C77.233 54.7206 81.2035 50.7501 81.2035 45.8698V34.4719ZM78.1566 45.8698C78.1566 49.07 75.553 51.6737 72.3527 51.6737C69.1525 51.6737 66.5488 49.07 66.5488 45.8698V34.4719C66.5488 31.2716 69.1525 28.668 72.3527 28.668C75.553 28.668 78.1566 31.2716 78.1566 34.4719V45.8698Z"
          fill="black"
        />
        <path
          d="M85.3802 27.0399C88.4649 27.0399 90.9745 24.5305 90.9745 21.4458C90.9745 18.3612 88.4651 15.8516 85.3802 15.8516C82.2955 15.8516 79.7861 18.361 79.7861 21.4458C79.7861 24.5305 82.2955 27.0399 85.3802 27.0399ZM85.3802 18.8986C86.7848 18.8986 87.9276 20.0412 87.9276 21.446C87.9276 22.8506 86.785 23.9932 85.3802 23.9932C83.9756 23.9932 82.833 22.8506 82.833 21.446C82.833 20.0412 83.9756 18.8986 85.3802 18.8986Z"
          fill="black"
        />
        <path
          d="M54.5114 81.545L44.852 76.2872C44.1133 75.885 43.188 76.1578 42.7856 76.8967C42.3832 77.6357 42.6565 78.5609 43.3954 78.9631L46.8557 80.8465C35.5615 80.2517 25.1101 77.7039 17.0062 73.5214C8.00455 68.8755 3.04688 62.8139 3.04688 56.4536C3.04688 48.7225 10.5875 41.3267 23.218 36.6699C24.0073 36.3788 24.4114 35.5029 24.1203 34.7136C23.8292 33.9242 22.9539 33.5202 22.1638 33.8113C15.5551 36.248 10.0472 39.5122 6.23512 43.2509C2.15597 47.2515 0 51.8169 0 56.4536C0 64.0109 5.54348 71.0339 15.6089 76.2291C24.0526 80.5871 34.8825 83.2472 46.5493 83.8806L42.9236 85.1926C42.1324 85.4788 41.7231 86.3522 42.0095 87.1434C42.2337 87.7633 42.8188 88.1488 43.4421 88.1488C43.6142 88.1488 43.7893 88.1194 43.9605 88.0574L54.3018 84.3153C54.8655 84.1113 55.2571 83.5956 55.3024 82.9978C55.3477 82.4 55.0381 81.8317 54.5114 81.545Z"
          fill="black"
        />
        <path
          d="M101.535 61.6824C100.784 61.3032 99.8674 61.6046 99.488 62.3558C99.2694 62.7884 99.0232 63.2245 98.7561 63.6519C98.3103 64.3655 98.5272 65.3053 99.2408 65.7512C99.4918 65.9082 99.7709 65.983 100.047 65.983C100.554 65.983 101.051 65.7291 101.34 65.2668C101.656 64.7614 101.948 64.244 102.208 63.7289C102.587 62.9779 102.286 62.0616 101.535 61.6824Z"
          fill="black"
        />
        <path
          d="M93.857 69.0741C93.034 69.7534 92.1351 70.4208 91.1853 71.0582C90.4868 71.527 90.3005 72.4734 90.7693 73.1722C91.0633 73.6103 91.5449 73.8469 92.0356 73.8469C92.3275 73.8469 92.6226 73.7632 92.8831 73.5884C93.9159 72.8953 94.896 72.1673 95.7961 71.4245C96.4451 70.8888 96.5369 69.9289 96.0014 69.2797C95.466 68.6305 94.506 68.5385 93.857 69.0741Z"
          fill="black"
        />
        <path
          d="M84.5534 74.6984C83.5486 75.1512 82.4952 75.5881 81.4224 75.9972C80.6364 76.297 80.2419 77.1774 80.5419 77.9635C80.7737 78.571 81.352 78.9446 81.9656 78.9446C82.146 78.9446 82.3296 78.9123 82.5082 78.8442C83.6365 78.4138 84.7458 77.9537 85.8051 77.4764C86.5721 77.1307 86.9137 76.2288 86.5682 75.4618C86.2227 74.6946 85.3208 74.3525 84.5534 74.6984Z"
          fill="black"
        />
        <path
          d="M74.1238 78.3053C73.0401 78.5844 71.9219 78.8461 70.8005 79.0833C69.9772 79.2574 69.4511 80.0656 69.625 80.8889C69.7765 81.6057 70.4093 82.0975 71.1141 82.0975C71.2185 82.0975 71.3246 82.0867 71.4308 82.0642C72.5955 81.818 73.7574 81.546 74.8837 81.2559C75.6984 81.0461 76.189 80.2155 75.9792 79.4008C75.7693 78.5861 74.9379 78.0955 74.1238 78.3053Z"
          fill="black"
        />
        <path
          d="M63.2082 80.3274C62.6836 80.3899 62.1479 80.4488 61.6166 80.5027C60.7795 80.5874 60.1695 81.3347 60.2542 82.1717C60.3336 82.9568 60.9956 83.5418 61.7681 83.5418C61.8195 83.5418 61.8713 83.5392 61.9235 83.5339C62.4727 83.4782 63.0262 83.4173 63.5688 83.3527C64.4042 83.2532 65.0008 82.4953 64.9013 81.6599C64.8018 80.8244 64.0443 80.2272 63.2082 80.3274Z"
          fill="black"
        />
        <path
          d="M99.239 44.8142C96.2876 41.4139 92.1174 38.4186 86.8449 35.9117C86.0846 35.5503 85.176 35.8735 84.8149 36.6334C84.4537 37.3933 84.7767 38.302 85.5366 38.6634C95.3339 43.3217 100.953 49.8058 100.953 56.4533C100.953 57.2946 101.635 57.9767 102.477 57.9767C103.318 57.9767 104 57.2946 104 56.4533C104 52.3699 102.398 48.454 99.239 44.8142Z"
          fill="black"
        />
      </g>
      <defs>
        <clip-path id="clip0_36_4435">
          <rect width="104" height="104" fill="white" />
        </clip-path>
      </defs>
    </svg>
  );
};

export const Mail = () => {
  return (
    <svg
      suppressHydrationWarning={true}
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="63"
      viewBox="0 0 81 63"
      fill="none"
    >
      <path
        d="M73.8809 0.650391H7.11914C3.19855 0.650391 0 3.84546 0 7.76953V55.2305C0 59.1413 3.18431 62.3496 7.11914 62.3496H73.8809C77.7916 62.3496 81 59.1653 81 55.2305V7.76953C81 3.85875 77.8157 0.650391 73.8809 0.650391ZM72.8978 5.39648L40.6509 37.6435L8.12515 5.39648H72.8978ZM4.74609 54.2477V8.72967L27.6031 31.3907L4.74609 54.2477ZM8.10206 57.6035L30.9735 34.7321L38.9876 42.6774C39.9153 43.5972 41.4124 43.5942 42.3363 42.6701L50.1504 34.856L72.8979 57.6035H8.10206ZM76.2539 54.2476L53.5064 31.5L76.2539 8.75229V54.2476Z"
        fill="#166CBF"
      />
    </svg>
  );
};

export const Location = () => {
  return (
    <svg
      suppressHydrationWarning={true}
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
    >
      <path
        d="M54.0226 53.5177C62.642 39.9925 61.5584 41.6799 61.8068 41.3272C64.9449 36.901 66.6035 31.6916 66.6035 26.2617C66.6035 11.8621 54.9185 0 40.5 0C26.1285 0 14.3965 11.8387 14.3965 26.2617C14.3965 31.6881 16.0899 37.0339 19.3308 41.5198L26.9771 53.5179C18.802 54.7742 4.9043 58.5181 4.9043 66.7617C4.9043 69.7668 6.8657 74.0493 16.2098 77.3865C22.7344 79.7167 31.3608 81 40.5 81C57.5899 81 76.0957 76.1792 76.0957 66.7617C76.0957 58.5166 62.2143 54.7767 54.0226 53.5177ZM23.2952 38.9093C23.2691 38.8685 23.2419 38.8286 23.2135 38.7893C20.5166 35.0792 19.1426 30.6821 19.1426 26.2617C19.1426 14.3936 28.699 4.74609 40.5 4.74609C52.2765 4.74609 61.8574 14.3979 61.8574 26.2617C61.8574 30.6892 60.5094 34.9371 57.9583 38.5495C57.7297 38.851 58.9224 36.9982 40.5 65.9057L23.2952 38.9093ZM40.5 76.2539C21.833 76.2539 9.65039 70.7669 9.65039 66.7617C9.65039 64.0697 15.9102 59.6434 29.7814 57.918L38.4987 71.5967C38.9344 72.2805 39.689 72.6943 40.4998 72.6943C41.3106 72.6943 42.0654 72.2803 42.501 71.5967L51.2181 57.918C65.0897 59.6434 71.3496 64.0697 71.3496 66.7617C71.3496 70.7329 59.2767 76.2539 40.5 76.2539Z"
        fill="#166CBF"
      />
      <path
        d="M40.5 14.3965C33.9575 14.3965 28.6348 19.7192 28.6348 26.2617C28.6348 32.8042 33.9575 38.127 40.5 38.127C47.0425 38.127 52.3652 32.8042 52.3652 26.2617C52.3652 19.7192 47.0425 14.3965 40.5 14.3965ZM40.5 33.3809C36.5745 33.3809 33.3809 30.1872 33.3809 26.2617C33.3809 22.3362 36.5745 19.1426 40.5 19.1426C44.4255 19.1426 47.6191 22.3362 47.6191 26.2617C47.6191 30.1872 44.4255 33.3809 40.5 33.3809Z"
        fill="#166CBF"
      />
    </svg>
  );
};

export const LocationSmall = () => {
  return (
    <svg
      suppressHydrationWarning={true}
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <g clip-path="url(#clip0_37_4659)">
        <path
          d="M19.8583 20.1964C22.3058 16.3559 21.9981 16.835 22.0686 16.7349C22.9597 15.4781 23.4307 13.9988 23.4307 12.457C23.4307 8.36824 20.1127 5 16.0186 5C11.9378 5 8.60645 8.36159 8.60645 12.457C8.60645 13.9979 9.08729 15.5158 10.0076 16.7896L12.1787 20.1964C9.85738 20.5532 5.91113 21.6162 5.91113 23.957C5.91113 24.8103 6.46807 26.0264 9.12134 26.9739C10.974 27.6356 13.4235 28 16.0186 28C20.8712 28 26.126 26.6311 26.126 23.957C26.126 21.6158 22.1844 20.5539 19.8583 20.1964ZM11.1333 16.0483C11.1258 16.0367 11.1181 16.0254 11.11 16.0143C10.3442 14.9608 9.9541 13.7122 9.9541 12.457C9.9541 9.08708 12.6677 6.34766 16.0186 6.34766C19.3625 6.34766 22.083 9.08829 22.083 12.457C22.083 13.7142 21.7002 14.9204 20.9759 15.9462C20.911 16.0318 21.2496 15.5057 16.0186 23.714L11.1333 16.0483ZM16.0186 26.6523C10.718 26.6523 7.25879 25.0943 7.25879 23.957C7.25879 23.1926 9.03626 21.9358 12.975 21.4459L15.4503 25.3299C15.574 25.5241 15.7883 25.6416 16.0185 25.6416C16.2487 25.6416 16.4631 25.524 16.5867 25.3299L19.062 21.4459C23.0008 21.9358 24.7783 23.1926 24.7783 23.957C24.7783 25.0847 21.3502 26.6523 16.0186 26.6523Z"
          fill="#4B4840"
        />
        <path
          d="M16.0186 9.08789C14.1608 9.08789 12.6494 10.5993 12.6494 12.457C12.6494 14.3148 14.1608 15.8262 16.0186 15.8262C17.8763 15.8262 19.3877 14.3148 19.3877 12.457C19.3877 10.5993 17.8763 9.08789 16.0186 9.08789ZM16.0186 14.4785C14.9039 14.4785 13.9971 13.5717 13.9971 12.457C13.9971 11.3424 14.9039 10.4355 16.0186 10.4355C17.1332 10.4355 18.04 11.3424 18.04 12.457C18.04 13.5717 17.1332 14.4785 16.0186 14.4785Z"
          fill="#4B4840"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_4659">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.0898438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const JobBag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
    >
      <path
        d="M25 7H18.875V5.25C18.875 4.78587 18.6906 4.34075 18.3624 4.01256C18.0342 3.68437 17.5891 3.5 17.125 3.5H11.875C11.4109 3.5 10.9658 3.68437 10.6376 4.01256C10.3094 4.34075 10.125 4.78587 10.125 5.25V7H4C3.53587 7 3.09075 7.18437 2.76256 7.51256C2.43437 7.84075 2.25 8.28587 2.25 8.75V22.75C2.25 23.2141 2.43437 23.6592 2.76256 23.9874C3.09075 24.3156 3.53587 24.5 4 24.5H25C25.4641 24.5 25.9092 24.3156 26.2374 23.9874C26.5656 23.6592 26.75 23.2141 26.75 22.75V8.75C26.75 8.28587 26.5656 7.84075 26.2374 7.51256C25.9092 7.18437 25.4641 7 25 7ZM11.875 5.25H17.125V7H11.875V5.25ZM25 8.75V16.625H4V8.75H25ZM4 22.75V18.375H25V22.75H4Z"
        fill="#131313"
        fill-opacity="0.75"
      />
      <path
        d="M13.625 15.75H15.375C15.6071 15.75 15.8296 15.6578 15.9937 15.4937C16.1578 15.3296 16.25 15.1071 16.25 14.875C16.25 14.6429 16.1578 14.4204 15.9937 14.2563C15.8296 14.0922 15.6071 14 15.375 14H13.625C13.3929 14 13.1704 14.0922 13.0063 14.2563C12.8422 14.4204 12.75 14.6429 12.75 14.875C12.75 15.1071 12.8422 15.3296 13.0063 15.4937C13.1704 15.6578 13.3929 15.75 13.625 15.75Z"
        fill="#131313"
        fill-opacity="0.75"
      />
    </svg>
  );
};

export const MapSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clip-path="url(#clip0_1016_216)">
        <path
          d="M14 14.875C15.933 14.875 17.5 13.308 17.5 11.375C17.5 9.442 15.933 7.875 14 7.875C12.067 7.875 10.5 9.442 10.5 11.375C10.5 13.308 12.067 14.875 14 14.875Z"
          stroke="#333333"
          stroke-opacity="0.75"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.75 11.375C22.75 19.25 14 25.375 14 25.375C14 25.375 5.25 19.25 5.25 11.375C5.25 9.05436 6.17187 6.82876 7.81282 5.18782C9.45376 3.54687 11.6794 2.625 14 2.625C16.3206 2.625 18.5462 3.54687 20.1872 5.18782C21.8281 6.82876 22.75 9.05436 22.75 11.375Z"
          stroke="#333333"
          stroke-opacity="0.75"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1016_216">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ClockSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
    >
      <g clip-path="url(#clip0_1016_208)">
        <path
          d="M12.9725 25.375C10.8877 25.375 8.84974 24.7079 7.11631 23.458C5.38287 22.2081 4.03183 20.4315 3.23402 18.353C2.43621 16.2745 2.22746 13.9874 2.63418 11.7809C3.0409 9.57432 4.04482 7.54749 5.51898 5.95667C6.99315 4.36584 8.87134 3.28248 10.9161 2.84357C12.9608 2.40467 15.0802 2.62993 17.0063 3.49088C18.9324 4.35182 20.5786 5.80978 21.7369 7.68039C22.8951 9.551 23.5133 11.7502 23.5133 14C23.5133 17.0168 22.4028 19.9101 20.426 22.0433C18.4492 24.1766 15.7681 25.375 12.9725 25.375ZM12.9725 4.37501C11.2084 4.37501 9.484 4.9395 8.01726 5.99711C6.55051 7.05472 5.40731 8.55794 4.73224 10.3167C4.05717 12.0754 3.88054 14.0107 4.22469 15.8778C4.56884 17.7448 5.41831 19.4598 6.66568 20.8059C7.91304 22.152 9.50229 23.0687 11.2324 23.4401C12.9626 23.8114 14.7559 23.6208 16.3857 22.8923C18.0155 22.1639 19.4084 20.9302 20.3885 19.3474C21.3685 17.7645 21.8916 15.9037 21.8916 14C21.8916 11.4473 20.952 8.99914 19.2793 7.1941C17.6066 5.38906 15.338 4.37501 12.9725 4.37501Z"
          fill="black"
          fill-opacity="0.75"
        />
        <path
          d="M17.4325 19.6875C17.3258 19.6882 17.22 19.6661 17.1213 19.6226C17.0225 19.579 16.9326 19.5148 16.8568 19.4337L12.3973 14.6212C12.3221 14.5395 12.2627 14.4425 12.2223 14.3359C12.182 14.2293 12.1615 14.1152 12.1621 14V7C12.1621 6.76794 12.2475 6.54538 12.3996 6.38128C12.5517 6.21719 12.7579 6.125 12.973 6.125C13.188 6.125 13.3942 6.21719 13.5463 6.38128C13.6984 6.54538 13.7838 6.76794 13.7838 7V13.6413L18.0082 18.1913C18.0842 18.2726 18.1446 18.3694 18.1857 18.476C18.2269 18.5826 18.2481 18.697 18.2481 18.8125C18.2481 18.928 18.2269 19.0424 18.1857 19.149C18.1446 19.2556 18.0842 19.3524 18.0082 19.4337C17.9325 19.5148 17.8426 19.579 17.7438 19.6226C17.645 19.6661 17.5393 19.6882 17.4325 19.6875Z"
          fill="black"
          fill-opacity="0.75"
        />
      </g>
      <defs>
        <clipPath id="clip0_1016_208">
          <rect width="25.9467" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DateSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M17.6752 5.42875C17.5765 3.78656 16.2052 2.5 14.5536 2.5H13.7496V1.875C13.7496 1.53 13.4696 1.25 13.1246 1.25C12.7796 1.25 12.4996 1.53 12.4996 1.875V2.5H7.49958V1.875C7.49958 1.53 7.21958 1.25 6.87458 1.25C6.52958 1.25 6.24958 1.53 6.24958 1.875V2.5H5.44552C3.79364 2.5 2.42239 3.78656 2.32395 5.42875C2.13864 8.5175 2.14208 11.6506 2.33427 14.7409C2.43177 16.3103 3.68895 17.5675 5.25833 17.665C6.83114 17.7628 8.4152 17.8116 9.99927 17.8116C11.583 17.8116 13.1674 17.7628 14.7402 17.665C16.3096 17.5675 17.5668 16.3103 17.6643 14.7409C17.8568 11.6522 17.8602 8.51937 17.6752 5.42875ZM16.4171 14.6634C16.3583 15.605 15.6043 16.3591 14.663 16.4175C11.5686 16.6097 8.43052 16.6097 5.33614 16.4175C4.39458 16.3587 3.64052 15.6047 3.58208 14.6634C3.43427 12.2875 3.40395 9.88656 3.48302 7.5H16.5165C16.5952 9.8875 16.5649 12.2884 16.4171 14.6634ZM6.87458 5C7.21958 5 7.49958 4.72 7.49958 4.375V3.75H12.4996V4.375C12.4996 4.72 12.7796 5 13.1246 5C13.4696 5 13.7496 4.72 13.7496 4.375V3.75H14.5536C15.5452 3.75 16.3683 4.52031 16.4274 5.50344C16.4421 5.75156 16.4483 6.00125 16.4605 6.25H3.53864C3.55114 6.00125 3.55708 5.75156 3.57177 5.50344C3.63083 4.52031 4.45364 3.75 5.44552 3.75H6.24958V4.375C6.24958 4.72 6.52958 5 6.87458 5Z" fill="#7A7A7A"/>
<path d="M6.875 11.25C7.39277 11.25 7.8125 10.8303 7.8125 10.3125C7.8125 9.79473 7.39277 9.375 6.875 9.375C6.35723 9.375 5.9375 9.79473 5.9375 10.3125C5.9375 10.8303 6.35723 11.25 6.875 11.25Z" fill="#7A7A7A"/>
<path d="M10 11.25C10.5178 11.25 10.9375 10.8303 10.9375 10.3125C10.9375 9.79473 10.5178 9.375 10 9.375C9.48223 9.375 9.0625 9.79473 9.0625 10.3125C9.0625 10.8303 9.48223 11.25 10 11.25Z" fill="#7A7A7A"/>
<path d="M6.875 14.375C7.39277 14.375 7.8125 13.9553 7.8125 13.4375C7.8125 12.9197 7.39277 12.5 6.875 12.5C6.35723 12.5 5.9375 12.9197 5.9375 13.4375C5.9375 13.9553 6.35723 14.375 6.875 14.375Z" fill="#7A7A7A"/>
<path d="M13.125 11.25C13.6428 11.25 14.0625 10.8303 14.0625 10.3125C14.0625 9.79473 13.6428 9.375 13.125 9.375C12.6072 9.375 12.1875 9.79473 12.1875 10.3125C12.1875 10.8303 12.6072 11.25 13.125 11.25Z" fill="#7A7A7A"/>
<path d="M13.125 14.375C13.6428 14.375 14.0625 13.9553 14.0625 13.4375C14.0625 12.9197 13.6428 12.5 13.125 12.5C12.6072 12.5 12.1875 12.9197 12.1875 13.4375C12.1875 13.9553 12.6072 14.375 13.125 14.375Z" fill="#7A7A7A"/>
<path d="M10 14.375C10.5178 14.375 10.9375 13.9553 10.9375 13.4375C10.9375 12.9197 10.5178 12.5 10 12.5C9.48223 12.5 9.0625 12.9197 9.0625 13.4375C9.0625 13.9553 9.48223 14.375 10 14.375Z" fill="#7A7A7A"/>
</svg>
  );
};

export const EventTimeSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.99984 1.00049C8.21984 1.00049 6.47981 1.52832 4.9998 2.51723C3.51978 3.50615 2.36625 4.91173 1.68508 6.55623C1.0039 8.20074 0.825673 10.0103 1.17293 11.7561C1.52019 13.5019 2.37734 15.1055 3.63599 16.3642C4.89464 17.6228 6.49826 18.48 8.24406 18.8272C9.98985 19.1745 11.7994 18.9963 13.4439 18.3151C15.0884 17.6339 16.494 16.4804 17.4829 15.0004C18.4718 13.5203 18.9997 11.7803 18.9997 10.0003C18.9969 7.61428 18.0478 5.32677 16.3606 3.63959C14.6734 1.9524 12.3859 1.0033 9.99984 1.00049ZM9.99984 17.3638C8.54347 17.3638 7.11982 16.932 5.9089 16.1228C4.69797 15.3137 3.75418 14.1637 3.19685 12.8182C2.63952 11.4727 2.4937 9.99215 2.77782 8.56377C3.06195 7.1354 3.76325 5.82334 4.79306 4.79354C5.82286 3.76374 7.13491 3.06243 8.56329 2.77831C9.99167 2.49419 11.4722 2.64001 12.8177 3.19733C14.1632 3.75466 15.3133 4.69846 16.1224 5.90938C16.9315 7.1203 17.3633 8.54396 17.3633 10.0003C17.361 11.9525 16.5844 13.8241 15.204 15.2045C13.8236 16.5849 11.952 17.3614 9.99984 17.3638Z" fill="#7A7A7A"/>
    <path d="M10.818 9.66091V5.09063C10.818 4.87364 10.7318 4.66553 10.5783 4.5121C10.4249 4.35866 10.2168 4.27246 9.99981 4.27246C9.78282 4.27246 9.57471 4.35866 9.42128 4.5121C9.26784 4.66553 9.18164 4.87364 9.18164 5.09063V9.99963C9.18169 10.2166 9.26792 10.4247 9.42136 10.5781L11.8759 13.0326C12.0302 13.1816 12.2368 13.2641 12.4514 13.2622C12.6659 13.2603 12.8711 13.1743 13.0228 13.0226C13.1745 12.8709 13.2605 12.6657 13.2624 12.4512C13.2643 12.2367 13.1818 12.03 13.0328 11.8757L10.818 9.66091Z" fill="#7A7A7A"/>
    </svg>
  );
};

export const EventLocationSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <mask id="mask0_1086_1280"  maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
    <path d="M0 1.90735e-06H20V20H0V1.90735e-06Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_1086_1280)">
    <path d="M5.11588 10.7591L8.77907 15.6429C9.05734 16.0139 9.50071 16.2539 10.0001 16.2539C10.4996 16.2539 10.9429 16.0139 11.2212 15.6429L14.8844 10.7591C15.6489 9.73985 16.1018 8.47348 16.1018 7.10136C16.1018 3.73151 13.37 0.999669 10.0001 0.999669C6.63024 0.999669 3.89844 3.73151 3.89844 7.10136C3.89844 8.47348 4.35141 9.73985 5.11588 10.7591Z" stroke="#8C8D8E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.5274 7.10104C11.5274 7.94349 10.8444 8.62646 10.002 8.62646C9.15953 8.62646 8.47656 7.94349 8.47656 7.10104C8.47656 6.25859 9.15953 5.57562 10.002 5.57562C10.8444 5.57562 11.5274 6.25859 11.5274 7.10104Z" stroke="#8C8D8E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.94922 19H13.0509" stroke="#8C8D8E" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
  );
};
