import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './Mission_info.css'

const Mission_info = ({ data }) => {
  return (
    <Container className=" mission-info mt-5">
        <hr/>
      <Row className=" d-flex align-items-center" data-aos="fade-right" data-aos-duration="1000">
        <Col  lg={5}>
          <p className="info-title">~30%</p>
        </Col>
        <Col lg={6} >
          <p className="info-content">With approximate 54,000 wind turbines and 7,000 gas turbines, GE Vernova's technology base helps generate approximately 30% of the world's electricity and has a meaningful role to play in the energy transition.</p>
        </Col>
      </Row>
      <hr/>
      <Row className="d-flex align-items-center" data-aos="fade-right" data-aos-duration="1000">
        <Col lg={5}>
          <p className="info-title">2030</p>
        </Col>
        <Col lg={6}>
          <p className="info-content">Our target for carbon neutrality in our facilities and operations.</p>
        </Col>
      </Row>
      <hr/>
    </Container>
  );
};

export default Mission_info;
