import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { OurAchevements_img } from "../../assets/images";

const OurAchievements = () => {
  const firstRow = [
    {
      title: "600+MW",
      subtitle: "Liasoning",
      content:
        "The only limit to our realization of tomorrow will be our doubts of today",
    },
    {
      title: "200MW",
      subtitle: "IPP",
      content:
        "The only limit to our realization of tomorrow will be our doubts of today",
    },
    {
      title: "100+ Locations",
      subtitle: "Liasoning",
      content:
        "The only limit to our realization of tomorrow will be our doubts of today",
    },
  ];
  const secondRow = [
    {
      title: "600+MW",
      subtitle: "Tamilnadu",
      content: "Wind Project",
    },
    {
      title: "300+MW",
      subtitle: "Tamilnadu",
      content: "Wind Project",
    },
    {
      title: "50+MW",
      subtitle: "Tamilnadu",
      content: "Wind Project",
    },
  ];

  return (
    <div className="nimalaan-main-section card_sec mt-130">
      <Container>
        <Row>
          <Col md={5} lg={4}>
            <img
            data-aos="fade-right" data-aos-duration="1200"
              className="img-fluid h-100 service"
              src={OurAchevements_img}
              alt="our-presence-is-in-india"
            />
          </Col>

          <Col md={7} lg={8}>
            <div className="our-achievements" data-aos="fade-right" data-aos-duration="1200">
              <h2 className="image-card-main">Our Achievements</h2>
              <p className="image-card-content">
                The only limit to our realization of tomorrow will be our
                doubts of today
              </p>

              <Row className="mt-4">
                {firstRow.map((item, index) => {
                  return (
                    <Col key={item?.title} md={6} lg={4} className="mb-md-3" >
                      <div className="achievement_card space" >
                        <h3 className="achievement_card_title">{item?.title}</h3>
                        <h4 className="achievement_card_subtitle">
                          {item?.subtitle}
                        </h4>
                        <p className="achievement_card_content">
                          {item?.content}
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              <p className="achievement_card_subtitle mt-30">Land Sourcing</p>
              <Row className="mt-4">
                {secondRow.map((item, index) => {
                  return (
                    <Col key={item?.title} md={6} lg={4} className="mb-md-3">
                      <div className="achievement_card ">
                        <h3 className="achievement_card_title">{item?.title}</h3>

                        <p className="achievement_card_content">
                          {item?.content}
                        </p>
                        <h4 className="achievement_card_subtitle mt-1">
                          {item?.subtitle}
                        </h4>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurAchievements;
