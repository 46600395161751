import React, { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { CoreTeam, Serviceteam, Technicalteam } from "../../assets/images";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";
import "./jointeam.css";

const JoinTeam = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 2.5,
    centerMode: false,
    variableWidth: false,
    infinite: true,
    
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
         
        
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const sliderRef = useRef();

  return (
    <div className="nimalaan-main-section mt-130">
      <h2
        className="nimalaan-section-title"
        data-aos="fade-up"
        data-aos-duration="300"
      >
        Join Our Team
      </h2>
      <div className="innovation-content">
        <Container>
          <Row>
            <Col
              lg={{ span: 6, offset: 3 }}
              xs={12}
              md={12}
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <p className="mt-1 text-center text-information text-content">
                We’re always on the lookout for talent. Come say hello and we’ll
                figure out if you’re a good fit!
              </p>
            </Col>
          </Row>
          <Row className="mt-45" data-aos="fade-down" data-aos-duration="1000">
            <Col xs={12} md={12} lg={12} style={{position:"relative"}}>
              <Slider ref={sliderRef} {...settings}>
                <div>
                  <img
                    className="img-fluid w-100"
                    src={CoreTeam}
                    alt="Nimalaan Team Img"
                  />
                </div>
                <div>
                  <img
                    className="img-fluid w-100"
                    src={Technicalteam}
                    alt="Nimalaan group Img"
                  />
                </div>
                <div>
                  <img
                    className="img-fluid w-100"
                    src={Serviceteam}
                    alt="Nimalaan group Img"
                  />
                </div>
              </Slider>
              <div className="custom-arrows">
                <IoChevronBackOutline
                  className="arrow"
                  onClick={() => sliderRef?.current?.slickPrev()}
                />
                <IoChevronForward
                  className="arrow"
                  onClick={() => sliderRef?.current?.slickNext()}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default JoinTeam;

{
  /* <div>
<img
  className="img-fluid "
  src={CoreTeam}
  alt="Nimalaan Team Img"
/>
</div>
<div>
<img
  className="img-fluid "
  src={Technicalteam}
  alt="Nimalaan group Img"
/>
</div>
<div>
<img
  className="img-fluid "
  src={Serviceteam}
  alt="Nimalaan group Img"
/>
</div> */
}
