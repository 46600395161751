import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../../components/header/Header";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import Banner_2 from "../../components/banner/Banner_2";
import Clientssecondtitle from "../../components/Clients-second-title/Clients-second-title";
import ClientsList from "../../components/Clients-List/Clients-List";
import ContactInfo from "../../components/contact-info/ContactInfo";
import Footer from "../../components/footer/Footer";
import { clientsBanner } from "../../utils/Banner";
import { Clients_List } from "../../utils/Clientslist";
import { ClientsSeo } from "../../utils/Seo";


const ClientsPage = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <HeaderSeo data={ClientsSeo} />
      <Header />
      <Banner_2 data={clientsBanner} />
      <Clientssecondtitle />
      <ClientsList data={Clients_List} />
      <ContactInfo />
      <Footer />
    </motion.div>
  );
};

export default ClientsPage;
