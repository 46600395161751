import React from 'react'
import { Spinner } from 'react-bootstrap'

const PageLoader = () => {
  return (
    <div className='page_loader_wrapper'>

        <div className='loader_box'>
        <Spinner animation="border" role="status" variant="primary"></Spinner>

        </div>




    </div>
  )
}

export default PageLoader