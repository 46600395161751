import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../../components/header/Header";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import Banner_2 from "../../components/banner/Banner_2";
import Mission_info from "../../components/Mission_info/Mission_info";
import ContactInfo from "../../components/contact-info/ContactInfo";
import Footer from "../../components/footer/Footer";
import { MissionSeo } from "../../utils/Seo";
import { MissionBanner } from "../../utils/Banner";

const Mission = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <HeaderSeo data={MissionSeo} />
      <Header />
      <Banner_2 data={MissionBanner} />
      <Mission_info />
      <ContactInfo />
      <Footer />
    </motion.div>
  );
};

export default Mission;
