import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { IoClose } from "react-icons/io5";
import { UPLOADER } from "../../assets/images";
import axiosInstance from "../../api/api";

const ApplyNow = () => {
  const { id } = useParams();
  const [buttonloader, setButtonloader] = useState(false);
  const [files, setFiles] = useState();
  const [response, setResponse] = useState();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      emailId: "",
      phone: "",
      resume: "",
      coverLetter: "",
      jobId: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required("Name is required")
        .min(3, "Name must be at least 3 characters")
        .max(100, "Name cannot exceed 100 characters"),
      emailId: Yup.string()
        .required("Email is required")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email id"
        )
        .min(2, "Email must be at least 3 characters")
        .max(255, "Name cannot exceed 100 characters"),
      phone: Yup.string()
        .required("Mobile no is required")
        .matches(
          /^(?!(0{10}))[^1-5]\d{9}$/,
          "Please enter a valid mobile number"
        ),
      resume: Yup.mixed().required("Resume is required"),
      coverLetter: Yup.string()
        .required("Message is required")
        .max(3000, "Message cannot exceed 3000 characters"),
    }),

    onSubmit: async (values, { setSubmitting }) => {
      const bodyData = {
        fullName: values?.fullName,
        phone: values?.phone,
        emailId: values?.emailId,
        resume: values?.resume,
        coverLetter: values?.coverLetter,
        jobId: `${id}`,
      };

      setButtonloader(true);

      try {
        const res = await axiosInstance.post("/jobs/apply", bodyData);

        setResponse(res?.data);

        if (res?.data?.status == true) {
          setButtonloader(false);
          Swal.fire({
            title: "Success!",
            text: "Your application has been submitted successfully",
            icon: "success",
            confirmButtonText: "OK",
          });
          formik.resetForm();
          formik.setFieldValue("resume", "");
          setFiles("");
        }

        if (res?.data?.status === false) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
          formik.setFieldValue("resume", "");
          setFiles("");
        }

        if (res?.data?.statusCode === 429) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
          formik.setFieldValue("resume", "");
          setFiles("");
        }

        if (res?.data?.statusCode === 400) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
          formik.setFieldValue("resume", "");
          setFiles("");
        }

        if (res?.data?.statusCode === 500) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
          formik.setFieldValue("resume", "");
          setFiles("");
        }

        if (res?.data?.statusCode === 404) {
          setButtonloader(false);
          Swal.fire({
            title: "Error!",
            text: "Something went wrong try again",
            icon: "error",
            confirmButtonText: "OK",
          });

          formik.resetForm();
          formik.setFieldValue("resume", "");
          setFiles("");
        }
      } catch (err) {
        setButtonloader(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },
  });

  const postFile = async (file) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    const postData = await axiosInstance.post("/upload", { file }, { headers });

    // formik.setFieldValue("resume", postData?.data?.url);
    formik.setFieldValue("resume", postData?.data?.image);
    

    return postData;
  };

  const handleResumeClear = () =>{

    setFiles(null)
    formik.setFieldValue("resume", "");


  }



  return (
    <Container>
      <div className="apply_now_section ">
        <h2 className="apply_now_title text-center">Apply Now</h2>

        <div className="join-now-card">
          <Form autocomplete="off" onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="name-input">
                  <Form.Label>
                    Full Name <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    {...formik.getFieldProps("fullName")}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <small className="text-danger d-block mt-1 ms-1">
                      {formik.errors.fullName}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-30" controlId="mobile-input">
                  <Form.Label>
                    Mobile No <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Mobile No"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <small className="text-danger d-block mt-1 ms-1">
                      {formik.errors.phone}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mb-30" controlId="email-input">
                  <Form.Label>
                    Email Id <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Id"
                    {...formik.getFieldProps("emailId")}
                  />
                  {formik.touched.emailId && formik.errors.emailId ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.emailId}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="h-100">
                  <Form.Control
                    type="file"
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];

                      if (file) {
                        const fileType = file.type;
                        const fileSize = file.size;
                        const validFileTypes = [
                          "application/pdf",
                          "application/msword",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          "text/plain",
                          "application/rtf",
                        ];
                        const maxFileSize = 2 * 1024 * 1024;

                        if (validFileTypes.includes(fileType)) {
                          if (fileSize <= maxFileSize) {
                            setFiles(file.name);
                            postFile(file);
                          } else {
                            Swal.fire({
                              title: "Error!",
                              text: "File is too large. Maximum allowed size is 2MB.",
                              icon: "error",
                              confirmButtonText: "OK",
                            });
                          }
                        } else {
                          Swal.fire({
                            title: "Error!",
                            text: "Invalid file type. Please upload a PDF, DOCX, DOC, RTF, or TXT file.",
                            icon: "error",
                            confirmButtonText: "OK",
                          });
                        }

                        event.currentTarget.value = null;
                      }
                    }}
                    className="d-none"
                    id="customFileUpload"
                  />
                  <label
                    className="upload_container"
                    htmlFor="customFileUpload"
                  >
                    <img src={UPLOADER} alt="uploader" />

                    <div>
                      <h5>Upload CV</h5>
                      <p>Use a pdf, docx, doc, rtf & txt and size should not exceed 2mb  </p>
                    </div>
                  </label> 
                  {files && (
                    <p className="uploaded_file">
                      <em>
                       <strong> Uploaded file :</strong><span className="chip_design"> <span className="text-crop">{files}</span>  <IoClose className="clear_resume" onClick={()=> handleResumeClear()} /></span>
                      </em>
                    </p>
                  )}
                  {formik.touched.resume && formik.errors.resume ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.resume}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>

              <Col lg={12}>
                <Form.Group className="mb-30" controlId="name-input">
                  <Form.Label>
                    Cover Letter <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={4}
                    placeholder="Enter Cover Letter..."
                    {...formik.getFieldProps("coverLetter")}
                  />
                  {formik.touched.coverLetter && formik.errors.coverLetter ? (
                    <small className="text-danger mt-1 ms-1">
                      {formik.errors.coverLetter}
                    </small>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Col lg={{ span: 2, offset: 5 }}>
              <div className="text-center">
                <button type="submit" className="primary-btn mt-5 w-100 submit">
                  {buttonloader === true ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                    />
                  ) : null}
                  <span> {buttonloader === true ? null : "Submit"} </span>
                </button>
              </div>
            </Col>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default ApplyNow;
