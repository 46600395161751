import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { OurPresence } from "../../assets/images";


const TeamExpertise = () => {
  const navigate = useNavigate()
  return (
    <div className="nimalaan-main-section mt-130">
      <Container>
        <Row>
          <Col lg={5}  data-aos="fade-up" data-aos-duration="1000">
            <div className="team-expertise">
              <h4 className="image-card-title">Team Expertise</h4>
              <h2 className="image-card-main">A Wholistic Approach</h2>
              <p className="image-card-content">
                With years of experience in the renewable energy industry, our
                team of experts is equipped to handle the most complex projects
                with ease. From site assessment and resource analysis to
                permitting, financing, and construction, we offer a full range
                of services to help our clients achieve their renewable energy
                goals.
              </p>

              <button className="primary-btn mt-4" onClick={()=>navigate('/join-now')}>Join now</button>
            </div>
          </Col>

          <Col lg={7} data-aos="fade-up" data-aos-duration="1000">
            <div className="map_card">
              <h4 className="image-card-title">Our presence is in india</h4>
              <img
                className="img-fluid mt-10"
                src={OurPresence}
                alt="our-presence-is-in-india"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TeamExpertise;
