import React from 'react'
import { useNavigate } from 'react-router-dom'
import {  Col, Container, Row } from 'react-bootstrap'
import moment from "moment";
import { ClockSvg, JobBag, MapSvg } from '../../assets/svg'

const JobView = ({viewData}) => {
     const navigate = useNavigate()
      const applyJob = (id) => {
        navigate(`/careers/apply-now/${id}`);};

        const findDays = (data) => {
        const parsedDate = moment(data);
        if (!parsedDate.isValid()) {
           return "Invalid date"; 
         }
         const now = moment();
         const diffYears = now.diff(parsedDate, "years");
         const diffMonths = now.diff(parsedDate, "months");
         const diffDays = now.diff(parsedDate, "days");
         const diffHours = now.diff(parsedDate, "hours");
         const diffMin = now.diff(parsedDate, "minutes");
       
         // Determine relative time
         let relativeTime;
       
         if (diffYears === 1) {
           relativeTime = "1 year ago";
         } else if (diffYears > 1) {
           relativeTime = `${diffYears} years ago`;
         } else if (diffMonths === 1) {
           relativeTime = "1 month ago";
         } else if (diffMonths > 1) {
           relativeTime = `${diffMonths} months ago`;
         } else if (diffDays === 1) {
           relativeTime = "1 day ago";
         } else if (diffDays > 1) {
           relativeTime = `${diffDays} days ago`;
         } else if (diffHours === 1) {
           relativeTime = "1 hour ago";
         } else  if (diffMin > 1) {
          relativeTime = `${diffMin} minutes ago`;
        } else  {
          relativeTime = `Few seconds ago`;
        }
         return  relativeTime ;
       };
      
     return (
    <div className='job_detail_main'>
     
        <Container>
          
            <Row>
                <Col lg={8} data-aos="fade-up" data-aos-duration="1000">
                  <div className='job-view-section'>
                    <span className='post_date'>  
                     {moment(viewData?.createdAt).format("MMMM DD, YYYY")}</span>
                    <h2 className='job_title'>{viewData?.jobTitle}</h2>
                    <div className='job_keys'>
                            <div className='job_keys_cont'>
                                 <ClockSvg />
                                 <p> {findDays(viewData?.createdAt)}</p>
                            </div>
                            <div className='job_keys_cont'>
                                 <MapSvg />
                                 <p>{viewData?.district.districtName}, {viewData?.state.stateName}</p>
                            </div>
                            <div className='job_keys_cont'>
                                 <JobBag />
                                 <p>{viewData?.job_shift.shiftName}</p>
                            </div>
                    </div>
                    <div className='job_overview'>
                        <p>Job Title:<span>&nbsp;{viewData?.jobTitle}</span></p>
                        <p>Job Category:<span>&nbsp;{viewData?.category.categoryName}</span></p>
                        <p>No of positions:<span>&nbsp;{viewData?.noOfPositions}</span></p>

                    </div>
                    <div className='job_view_description'>
                        <h5>Job Description:</h5>
                        <div dangerouslySetInnerHTML={{ __html: viewData?.description }} />
                    </div>
                    <div className='job_view_description'>
                        <h5>Responsibilities:</h5>
                        <div dangerouslySetInnerHTML={{ __html: viewData?.keyResponsibility }} />
                    </div>
                    <div className='job_view_description'>
                        <h5>Skills Required:</h5>
                        <div className='chip-group'>
                         {viewData?.skills.map((skill, index) => (
                           <span className='chip' key={index}>{skill.skillName}</span>
                        ))}  
                        </div>
                     </div>
                   <button className='job-view-btn' onClick={() => applyJob(viewData?.id, viewData?.jobTitle)}>Apply Now</button>
                  </div>
                </Col>
                <Col lg={{span: 3 , offset:1}} data-aos="fade-up"data-aos-duration="1000">
                 <div className='job-info-section'>

                      <h2 className='job_title '>Job Information</h2>
                      <div className='job-info-overview'>
                      <div className='job-info-section-container'>
                        <span> Gender</span>
                        <h5>{(viewData?.gender)?.charAt(0) + viewData?.gender?.slice(1)?.toLowerCase()}</h5>
                     </div>
                        <div className='job-info-section-container'>
                        <span>Experience</span>
                        <h5>{viewData?.experience === 0 ? "Fresher" : viewData?.experience === 1 ? viewData?.experience + "  " + "Year" :  viewData?.experience + "  " + "Years"}</h5>
                     </div>
                     { 
                        viewData?.hideSalary  != true ? (
                           <div className='job-info-section-container'>
                           <span>Salary</span>
                           <h5>{viewData?.salaryFrom} - {viewData?.salaryTo} LPA</h5>
                        </div>
                        ) : null

                     }
                   
                     <div className='job-info-section-container'>
                        <span>Posting Date</span>
                        <h5>{moment(viewData?.createdAt).format("DD/MM/YYYY")}</h5>
                     </div>
                     <div className='job-info-section-container'>
                        <span>Expiring Date</span>
                        <h5>{moment(viewData?.jobExpiryDate).format("DD/MM/YYYY")}</h5>
                     </div>
                     <div className='job-info-section-container'>
                        <span>Career Level</span>
                        <h5>{viewData?.carrier.careerName}</h5>
                     </div>
                     <div className='job-info-section-container'>
                        <span>Degree Level</span>
                        <h5>{viewData?.DegreeLevel.degreeName}</h5>
                     </div>
                     <div className='job-info-section-container'>
                        <span>Is Freelance</span>
                        <h5>{ viewData?.isFreelance == true ? 'Yes' : 'No' }</h5>
                     </div>
                   
                     </div>
                     <div className='job-info-section-container'>
                        <span>Job Tag</span>
                        <div className='chip-group'>
                        {viewData?.jobTag.map((tag, index) => (
                          <span className='chip' key={index}>{tag}</span>
                        ))}
                        </div>
                    </div>
                 </div>
                </Col>
              </Row>
            </Container>
          </div>
  )
}

export default JobView