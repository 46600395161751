import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { WhyNimalaanImg } from "../../assets/images";

const WhyNimalaan = () => {
  const firstRow = [
    {
      subtitle: "Expertise",
      content:
        "Highly skilled team of people who deliver best-in-class results tailored to your specifications.",
    },
    {
      subtitle: "Consistency",
      content:
        "Overseeing the entire project from start to finish gives deep understanding and ensures a fully standardised approach.",
    },
    {
      subtitle: "Partnership",
      content:
        "We consult with care, collaborating with everyone from landowners to investors to drive the best outcomes for all.",
    },
    {
      subtitle: "Outstanding results",
      content:
        "Our focus on excellence means we deliver successful projects that meet the needs of the modern energy market.",
    },
  ];


  return (
    <div className="nimalaan-main-section card_sec mt-130">
      <Container>
        <Row>
          <Col md={7} lg={7} data-aos="fade-down" data-aos-duration="1000">
            <div>
              <h2 className="image-content-card">Why Nimalaan</h2>
              <p className="image-card-content">
                Collaboration is central to how we work. We combine our
                technology and talents to deliver better outcomes for
                people and the planet. With 40 years of experience and 
                over 4,500 people employed around the world, we’re able to put
                our expertise to good 
                use and power positive change.
              </p>

              <Row className="mt-4">
                {firstRow.map((item, index) => {
                  return (
                    <Col key={item?.title} md={6} lg={6} className="mt-2">
                      <div className="achievement_card">
                        <h3 className="achievement_card_title">{item?.title}</h3>
                        <h4 className="achievement_card_subtitle">
                          {item?.subtitle}
                        </h4>
                        <p className="achievement_card_content">
                          {item?.content}
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col md={5} lg={5} data-aos="fade-down" data-aos-duration="1000">
            <img
              className="img-fluid h-100 service pl-50"
              src={WhyNimalaanImg}
              alt="why nimalaan"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyNimalaan;
