import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import Banner_2 from '../../components/banner/Banner_2';
import { EventsBanner } from '../../utils/Banner';
import ContactInfo from '../../components/contact-info/ContactInfo';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import EventsCard from '../../components/Event-card/EventsCard';
import axiosInstance from '../../api/api';
import { EventsSeo } from "../../utils/Seo";

const Events = () => {
  
  
  
 

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);



  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1 } }}>
      <HeaderSeo data={EventsSeo} />
      <Header />
      <Banner_2 data={EventsBanner} />
      <EventsCard />
      <ContactInfo />
      <Footer />
    </motion.div>
  )

}

export default Events