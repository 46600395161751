import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import HeaderSeo from "../../components/head-seo/HeaderSeo";
import ContactInfo from "../../components/contact-info/ContactInfo";
import Footer from "../../components/footer/Footer";
import { ContactBanner } from "../../utils/Banner";
import { ContactSeo } from "../../utils/Seo";

const ContactUs = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <div className="contact_us_page">
        <HeaderSeo data={ContactSeo} />
        <Header />
        <Banner data={ContactBanner} />
        <ContactInfo />
        <Footer />
      </div>
    </motion.div>
  );
};

export default ContactUs;
