import { Clients_EDF,Clients_JSW,Clients_msolar,Clients_FORTUM,Clients_SUSTEN } from "../assets/images"



export const Clients_List = [
    {
  
    title : "edf",
    content :"Lorem ipsum dolor sit amet consectetur. Aliquet nunc egestas sapien massa tincidunt ultrices. Dolor lobortis arcu ut ipsum interdum vestibulum quis neque sapien. Ut nisi eu eget tincidunt. Dictumst consectetur pretium gravida eros lectus purus dignissim bibendum pellentesque. ",
    image: Clients_EDF,
    alt:"edf"
},
{
  
    title : "JSW",
    content :"Lorem ipsum dolor sit amet consectetur. Aliquet nunc egestas sapien massa tincidunt ultrices. Dolor lobortis arcu ut ipsum interdum vestibulum quis neque sapien. Ut nisi eu eget tincidunt. Dictumst consectetur pretium gravida eros lectus purus dignissim bibendum pellentesque. ",
    image: Clients_JSW,
    alt:"JSW"
},
{
  
    title : "Fortum",
    content :"Lorem ipsum dolor sit amet consectetur. Aliquet nunc egestas sapien massa tincidunt ultrices. Dolor lobortis arcu ut ipsum interdum vestibulum quis neque sapien. Ut nisi eu eget tincidunt. Dictumst consectetur pretium gravida eros lectus purus dignissim bibendum pellentesque. ",
    image: Clients_FORTUM,
    alt:"Fortum"
},
{
  
    title : "M+ Solar",
    content :"Lorem ipsum dolor sit amet consectetur. Aliquet nunc egestas sapien massa tincidunt ultrices. Dolor lobortis arcu ut ipsum interdum vestibulum quis neque sapien. Ut nisi eu eget tincidunt. Dictumst consectetur pretium gravida eros lectus purus dignissim bibendum pellentesque. ",
    image: Clients_msolar,
    alt:"M+ Solar"
},
{
  
    title : "Susten",
    content :"Lorem ipsum dolor sit amet consectetur. Aliquet nunc egestas sapien massa tincidunt ultrices. Dolor lobortis arcu ut ipsum interdum vestibulum quis neque sapien. Ut nisi eu eget tincidunt. Dictumst consectetur pretium gravida eros lectus purus dignissim bibendum pellentesque. ",
    image: Clients_SUSTEN,
    alt:"Susten"
}
]