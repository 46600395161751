import { AboutusBanner, JoinNowImage, NimalaanBanner,ConatctUsImg, ServiceBannerImage, CARRIERBANNER, EVENTSBANNER } from "../assets/images";
import { ClientsBanner,AchevementsBanner,Mission_Banner} from "../assets/images";



export const homeBanner = {
  
    title : "Powering Tomorrow, Sustainably Today",
    content :"Explore our cutting-edge renewable energy solutions to reduce your carbon footprint and contribute to a sustainable world.",
    image: NimalaanBanner,
    alt:"imaalan-powering",
    joinnow:true,
    type:1,
}

export const joinBanner = {
  
    title : "Join Now! We would love to help you.",
    content :"We’d love to hear from you, no matter what the challenge may be. So, if you’re looking for an evidential Indian design studio then give us a call or drop us a line.",
    image: JoinNowImage,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}

export const aboutBanner = {
  
    title : "About Nimalaan",
    content :"We live our mission, celebrate the people making it happen and transform the way the world produces and consumes energy. Nimalaan is a leading provider of forward-thinking primary research and analysis on the trends driving the transition to a lower-carbon economy.",
    image: AboutusBanner,
    alt:"about-us-nimalaan",
    joinnow: false,
    type:2,
}
export const clientsBanner = {
  
    title : "Our Clients",
    content :"Lorem ipsum dolor sit amet consectetur. Aliquet nunc egestas sapien massa tincidunt ultrices. Dolor lobortis arcu ut ipsum interdum vestibulum quis neque sapien. Ut nisi eu eget tincidunt. Dictumst consectetur pretium gravida eros lectus purus dignissim bibendum pellentesque.",
    image: ClientsBanner,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}

export const AchievementsBanner = {
  
    title : "Achievements",
    content :"Lorem ipsum dolor sit amet consectetur. Aliquet nunc egestas sapien massa tincidunt ultrices. Dolor lobortis arcu ut ipsum interdum vestibulum quis neque sapien. Ut nisi eu eget tincidunt. Dictumst consectetur pretium gravida eros lectus purus dignissim bibendum pellentesque. Mattis eu nisi platea quam neque adipiscing. Ipsum egestas adipiscing orci sed elit ac. Semper tincidunt aliquet nulla proin tempus praesent.",
    image: AchevementsBanner,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}
export const ServiceBanner = {
  
    title : " Nimalaan Energy service solutions",
    content :"We support companies and countries to reduce emissions across the energy landscape – for a more reliable, affordable, and sustainable energy system",
    image: ServiceBannerImage,
    alt:"Nimalaan Energy service solutions",
    joinnow: true,
    type:1,
    name : "services",
    col:7

}

export const MissionBanner = {
  
    title : "Our mission is clear",
    content: [
        "Addressing the climate crisis is an urgent global priority and we take our responsibility seriously. That is our singular mission at GE Vernova: continuing to electrify the world while simultaneously working to help decarbonize it.",
        "If we want our energy future to be different…we must be different.",
        "Our mission is embedded in our name. We retain our treasured legacy, “GE,” in our name as an enduring and hard-earned badge of quality and ingenuity. “Ver” / “verde” signal Earth’s verdant and lush ecosystems. “Nova,” from the Latin “novus,” nods to a new, innovative era of lower carbon energy that GE Vernova will help deliver.",
        "Together, we have The Energy to Change the World."
    ],
    
    image: Mission_Banner,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:3,
}

export const CarrierBanner = {
  
    title : "Join Our Team at Nimalaan",
    content :"We know that finding a meaningful and rewarding career can be a long journey. Our goal is to make that process easy for you and to create a work environment that's enriching—one that you'll look forward to every day.",
    image: CARRIERBANNER,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}

export const EventsBanner = {
  
    title : "Events",
    content :"Solar Business Events (SBE) is a portfolio of leading solar exhibitions and conferences including Solar Business Festival, Solar Business Expo, Solar Pioneer Awards Ceremony, Women in Solar Forum, and Solar Career Fair. Solar Business Events is an event division of Global Attain Advancement, LLC, known as GAA Exhibitions & Conferences. ",
    image: EVENTSBANNER,
    alt:"join-now-nimalaan",
    joinnow: false,
    type:2,

}

export const ServiceData = [
    {
        box1:{
            data1 : "data",
            data2 : "data"
        },
        box2:{
            data1 : "data",
            data2 : "data"
        },
        box3:{
            data1 : "data",
            data2 : "data"
        },
        box4:{
            img:"image1.png"
        },
        box5:{
            data1 : "data",
            data2 : "data"
        },
        box6:{
            data1 : "data",
            data2 : "data"
        },
        box7:{
            data1 : "data",
            data2 : "data"
        },


    }
]

export const ContactBanner = {
  
    title : "Contact Us",
    content :"Lorem ipsum dolor sit amet consectetur. Aliquet nunc egestas sapien massa tincidunt ultrices. Dolor lobortis arcu ut ipsum interdum vestibulum quis neque sapien. Ut nisi eu eget tincidunt. Dictumst consectetur pretium gravida eros lectus purus dignissim bibendum pellentesque. ",
    image: ConatctUsImg,
    alt:"Conatct-us-nimalaan",
    joinnow: false,
    type:2,
  

}