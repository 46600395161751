import React from 'react'
import { Container } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const BreadCrumbCustom = (props) => {
  return (
    <Container> 
    <Breadcrumb className='custom_beadcrumb'>
      <Breadcrumb.Item href={props?.mainLink}>{props?.mainTitle}</Breadcrumb.Item>
      {
        props.secondLink && props.secondTitle && (
          <Breadcrumb.Item active={!props.thirdTitle} href={props?.secondLink}>
            {props?.secondTitle}
          </Breadcrumb.Item>
        )
      }
      {
         props.thirdTitle && (
          <Breadcrumb.Item active={true}>
            {props?.thirdTitle}
          </Breadcrumb.Item>
        )
      }
    </Breadcrumb>
  </Container>
  )
}

export default BreadCrumbCustom