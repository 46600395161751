import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import moment from 'moment';
import EventDetailBanner from '../../components/EventDetail-banner/EventDetailBanner';
import { DateSvg, EventLocationSvg } from '../../assets/svg'
import axiosInstance from "../../api/api"
import EventGallery from '../Event-gallery/EventGallery';
import PageLoader from '../page-loader/PageLoader';

const EventDetail = () => {
  const { id } = useParams();
  const [viewData, setViewData] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setLoader(true)
    const fetchData = async () => {
      try {
        const res = await axiosInstance.get(`/events/web/view/${id}`);

        if (res?.data?.status != true) {
          setLoader(false)
          navigate("/404-not-found", { replace: true });
        } else {
          setViewData(res?.data?.data)
          setLoader(false)
        }
      } catch (error) {
    }
  };
  fetchData();
  }, [id]);

  return (
    <div>
      <Container>
        <EventDetailBanner bannerImage={viewData[0]?.bannerImageUrl} />
        <div className='event-content'>
          <h1 className='event_title'>{viewData[0]?.eventTitle}</h1>
          <div className='event_timing'>
            <div className='event_location'>
              <DateSvg />
              <p>{moment(viewData[0]?.eventDate).format('dddd, DD MMMM, YYYY')}</p>
            </div>
            <div className='event_location'>
              <EventLocationSvg />
              <p>{viewData[0]?.eventLocation}</p>
            </div>
          </div>
          <div className='event-descrp'>
            <div dangerouslySetInnerHTML={{ __html: viewData[0]?.eventDescription }} />
          </div>
        </div>
        <EventGallery viewGallery={viewData[0]?.eventsgallery} />
      </Container>
      {
        loader == true ? (<PageLoader />) : null
      }
    </div>
  )
}

export default EventDetail