import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { PROJECTMANGEMENT } from "../../assets/svg";


const Innovation = () => {
  const navigate = useNavigate()

  return (
    <div className="nimalaan-main-section mt-130">
      <h2 className="nimalaan-section-title" data-aos="fade-up" data-aos-duration="1000">
        Innovating for a Greener tomorrow
      </h2>
      <div className="section-sub-container">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <p className="mt-1 text-center" data-aos="fade-up" data-aos-duration="1000">
                At our company, we believe that together, we can make a
                difference. We are committed to making renewable energy
                accessible and affordable for all, so that we can create a
                cleaner, more sustainable future for generations to come.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <p className="mt-4 text-center" data-aos="fade-up" data-aos-duration="1000">
                We are a leading one-stop project management solution provider
                of solar and wind energy solutions, offering innovative,
                cost-effective products and services to businesses across.
              </p>
            </Col>
          </Row>
          <div className="mt-50">
            <Row>
              <Col lg={{ span: 10, offset: 1 }}>
                <Row className="oreder-change-row">
                  <Col md={4} lg={4} >
                    <div className="innovation_card" data-aos="fade-right" data-aos-duration="1000">
                      <div className="text-center">
                        <h2>100+ MW</h2>
                        <h6>
                          Wind Project, <span>Tamilnadu</span>
                        </h6>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} lg={4} data-aos="fade-up" data-aos-duration="1000">
                    <div className="text-center pt-20">
                      <button className="primary-btn " onClick={() => {navigate("/join-now")}}>Join now</button>
                    </div>
                    <div className="innovation_card mt-50">
                      <div className="text-center">
                        <h2>300+ MW</h2>
                        <h6>
                          Wind Project, <span>Karnataka</span>
                        </h6>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} lg={4}>
                    <div className="innovation_card" data-aos="fade-left" data-aos-duration="1000">
                      <div className="text-center">
                        <h2>50+ MW</h2>
                        <h6>
                          Wind Project, <span>Maharastra</span>
                        </h6>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="nimalaan_image_section mt-100">
            <Row>
              <Col lg={6}>
                <div className="nimalaan_image_section_content">
                  <div className="nimalaan_product_management" data-aos="fade-up" data-aos-duration="1000">
                    <PROJECTMANGEMENT />
                    <div>
                      <h5>360 Project Management</h5>
                      <h6>
                        We are dedicated to providing comprehensive project
                        management solutions for wind and solar energy projects.{" "}
                      </h6>
                    </div>
                  </div>
                  <div className="nimalaan_renwable mt-35" data-aos="fade-up" data-aos-duration="1000">
                    <h1>Turnkey development of Renewable Energy Projects</h1>
                    <p className="mt-20">
                      We are a one-stop solution provider, offering a wide range
                      of services to help our clients successfully assess,
                      develop, and manage their renewable energy projects. With
                      years of experience in the renewable energy industry, our
                      team of experts is equipped to handle the most complex
                      projects with ease.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Innovation;
