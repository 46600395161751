export const HomeSeo = {

    title : "Home | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const ServiceSeo = {

    title : "Services | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const ContactSeo = {

    title : "Contact | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const ClientsSeo = {

    title : "Clients | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const MissionSeo = {

    title : "Mission | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const AchievementsSeo = {

    title : "Achievements | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const AboutSeo = {

    title : "About | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const JoinSeo = {

    title : "Join-now | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const CareersSeo = {

    title : "Careers | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const CareerDetailsSeo = {

    title : "Careers | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const CareerApplySeo = {

    title : "Careers | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const EventsSeo = {

    title : "Events | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}

export const EventDetailsSeo = {

    title : "Events | Nimalaan Energies - Harnessing Renewable Energy for a Sustainable Future", 
    keywords : "renewable energy solutions, wind energy projects, solar energy projects, sustainable energy solutions, carbon footprint reduction, clean energy technologies, project management solutions",
    description : "Discover innovative windmill and solar power solutions at Nimalaan Energies. Empower your home or business with clean, renewable energy sources today. management solutions"

}