import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";


const Banner = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div className={ data?.type === 2 ? "nimalaan_banner type_2" : "nimalaan_banner" }>
      <Container>
        <Row>
          <Col lg={data?.col || '6'}>
            <div className="nimalaan_banner_inner_section">
              <div className="mt-130">
                <h1 data-aos="fade-up" data-aos-duration="1000">{data?.title}</h1>
                <p data-aos="fade-up" data-aos-duration="1000">{data?.content}</p>
                {data?.joinnow === true ? (
                  <button data-aos="fade-up" data-aos-duration="1000" className="primary-btn" onClick={() => {
                    navigate("/join-now");
                  }}>Join now</button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Col>
          {
            data.type === 2 ?
            <Col className="text-center" lg={6}>
              <img
              data-aos="zoom-in"
                className="nimaalan-powering-banner-image_type_2_image img-fluid w"
                src={data?.image}
                alt={data?.alt}
              />
            </Col> : ""
          }
        </Row>
      </Container>
      {
        data.type === 1 ?
        <img
        data-aos="zoom-in"
        className="nimaalan-powering-banner-image"
        src={data?.image}
        alt={data?.alt}
      /> : ""
      }
      
    </div>
  );
};

export default Banner;
