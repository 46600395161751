import {Routes, Route, useLocation} from 'react-router-dom'
import Home from './pages/home/Home';
import NotFound from './pages/Not-found/NotFound';
import JoinNow from './pages/join-now/JoinNow';
import About from './pages/about/About';
import ClientsPage from './pages/Clients/Clients';
import Achievements from './pages/Achievements/Achievements';
import Mission from './pages/Mission/Mission';
import ServicePage from './pages/Services-page/ServicePage';
import ContactUs from './pages/contact-us/ContactUs';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { AnimatePresence } from 'framer-motion';
import './aos.css';
import { useEffect } from 'react';
import AOS from 'aos';
import Carrier from './pages/Carrier/Carrier';
import CarrierApply from './pages/Carrier-Apply/CarrierApply';
import CarrierDetail from './pages/Carrier-detail/CarrierDetail';
import Events from './pages/Events/Events';
import EventsDetails from './pages/Events-details/EventsDetails';
// import 'aos/dist/aos.css';


function App() {

  const location = useLocation();
  useEffect(()=>{
    AOS.init({
      disable: function() {
        var maxWidth = 800;
        return window.innerWidth < maxWidth;
      }
    });
  },[])
  

  return (
   <div >
   <AnimatePresence initial={false} mode='wait'>
   <Routes location={location} key={location?.pathname}>
    <Route path='/' element={<Home />} />
    <Route path='/services' element={<ServicePage />} />
    <Route path='/contact-us' element={<ContactUs />} />
    <Route path='*' element={<NotFound />} />
    <Route path='/404-not-found' element={<NotFound />} />
    <Route path='/join-now' element={<JoinNow />} />
    <Route path='/about' element={<About/>} />

    
    <Route path='/Clients' element={<ClientsPage/>} />
    <Route path='/careers' element={<Carrier/>} />
    <Route path='/careers/careers-detail/:name/:id' element={<CarrierDetail/>} />
    <Route path='/careers/apply-now/:id' element={<CarrierApply/>} />
    <Route path='/Achievements' element={<Achievements/>} />
    <Route path='/Mission' element={<Mission/>} />
    <Route path='/events' element={<Events/>} />
    <Route path='/events/events-detail/:id' element={<EventsDetails/>} />
    </Routes>
    </AnimatePresence>
   </div>
  );
}

export default App;
