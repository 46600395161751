import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Feasibility, LandSouring, PermitsandApporoves } from "../../assets/images";


const ServicePageCard = () => {
  const navigate = useNavigate();
  return (
    <div className="nimalaan-main-section">
     <div>
      <h2 className="nimalaan-section-title" data-aos="fade-up" data-aos-duration="500">
        Feasibility Analysis for Wind and Solar Sites
      </h2>
      <div className="section-sub-container">
        <Container>
          <Row data-aos="fade-up" data-aos-duration="500">
            <Col lg={{ span: 10, offset: 1 }}>
              <p className="mt-1  text-center">
                For feasibility analysis of wind and solar sites, several
                factors need to be considered. Here's a general outline of the
                steps and considerations involved in conducting such an
                analysis:
              </p>
            </Col>
          </Row>
          

          <div className="service_page_card">
            <div className="service_page_card_content">
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Resource Assessment</h2>
                <ul>
                  <li>
                    wind : Analyze historical wind data, ideally collected over
                    several years, to determine the average wind speed,
                    directionality, and variability at the site
                  </li>
                  <li>
                    Solar : Assess solar irradiance data to determine the solar
                    potential of the site.
                  </li>
                </ul>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Site Suitability</h2>
                <p>
                  Evaluate the geographical and environmental characteristics of
                  the site, including terrain, elevation, proximity to
                  transmission lines, land use regulations, and environmental
                  constraints (e.g., protected areas, wildlife habitats).
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Technical Feasibility</h2>
                <p>
                  Wind: Assess the technical feasibility of wind energy
                  generation by considering factors such as turbine technology,
                  layout optimization, wind turbine performance, and potential
                  wind turbine wakes.
                </p>
              </div>
            </div>
            <div className="service_page_card_image" data-aos="fade-up" data-aos-duration="1000">
              <img
                className="img-fluid"
                src={Feasibility}
                alt="feasibility-analysis"
              />
              <div className="text-center">
                <button className="primary-btn " onClick={()=> navigate('/join-now')}>Join now</button>
              </div>
            </div>
            <div className="service_page_card_content">
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Economic Viability</h2>
                <p>
                  Conduct a financial analysis to determine the economic
                  feasibility of the project, including capital costs,
                  operational expenses, maintenance costs, financing options,
                  and potential revenue streams (e.g., power purchase
                  agreements, renewable energy certificates).
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Permitting Considerations</h2>
                <p>
                  Identify regulatory requirements, permitting processes, and
                  environmental assessments needed for project development,
                  including obtaining permits for land use, environmental impact
                  assessments, and compliance with local, state, and federal
                  regulations.
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Risk Assessment</h2>
                <p>
                  Evaluate potential risks and uncertainties associated with the
                  project, including regulatory risks, market risks, technical
                  risks, and natural hazards (e.g., extreme weather events,
                  seismic activity).
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
     </div>

       {/* land source  */}

       <div className="mt-130">
      <h2 className="nimalaan-section-title" data-aos="fade-down" data-aos-duration="500">
      Land Sourcing
      </h2>
      <div className="section-sub-container" >
        <Container>
          <Row data-aos="fade-down" data-aos-duration="500">
            <Col lg={{ span: 10, offset: 1 }}>
              <p className="mt-1  text-center">
              Acquiring land for renewable energy projects involves several steps and considerations. Here's  <br /> a general outline of the process:
              </p>
            </Col>
          </Row>
          

          <div className="service_page_card">
            <div className="service_page_card_content">
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Identifying Suitable Sites</h2>
                <p>Conduct a site selection process based on criteria such as solar irradiance levels, wind resources, proximity to existing infrastructure (e.g., transmission lines), land availability, landowner willingness to lease or sell.</p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Engaging Landowners</h2>
                <p>
                Negotiate lease or purchase agreements that address terms such as land use rights, lease duration, rental payments, easements, and potential royalties. Reach out to landowners within the target area to discuss the possibility of leasing or selling their land for renewable energy development.
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Conducting Due Diligence</h2>
                <p>
                Perform due diligence on prospective sites to assess factors such as land title, zoning regulations, environmental constraints (e.g., wetlands, endangered species habitats), cultural heritage considerations, and potential land use conflicts.
                </p>
              </div>
            </div>
            <div className="service_page_card_image" data-aos="fade-up" data-aos-duration="1000">
              <img
                className="img-fluid"
                src={LandSouring}
                alt="land-sourcing"
              />
              <div className="text-center">
                <button className="primary-btn " onClick={()=> navigate('/join-now')}>Join now</button>
              </div>
            </div>
            <div className="service_page_card_content">
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Securing Permits and Approvals</h2>
                <p>
                Obtain necessary permits and approvals for land use, environmental impact assessments, and project development from relevant regulatory agencies at the local, state, and federal levels. studies as required to support permit applications
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Community Engagement</h2>
                <p>
                Engage with local communities, stakeholders, and indigenous groups to address concerns, gather input, and build support for the project. Conduct public consultations, community meetings, and stakeholder engagement activities
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Navigating Regulatory Processes</h2>
                <p>
                Navigate through the regulatory processes involved in land acquisition, including land use planning, zoning approvals, environmental permitting, and compliance with renewable energy policies and incentives.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
     </div>

     {/* Permits  */}

     <div className="mt-130">
      <h2 className="nimalaan-section-title" data-aos="fade-up" data-aos-duration="500">
      Permits and Approvals
      </h2>
      <div className="section-sub-container">
        <Container>
          <Row data-aos="fade-up" data-aos-duration="500">
            <Col lg={{ span: 10, offset: 1 }}>
              <p className="mt-1  text-center">
              Securing permits and approvals is a crucial aspect of renewable energy project development. Several companies and consultants offer services to assist with navigating the permitting process.
              </p>
            </Col>
          </Row>
          

          <div className="service_page_card">
            <div className="service_page_card_content">
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Regulatory Compliance Services</h2>
                <p>Companies specializing in regulatory compliance offer expertise in understanding and adhering to local, state, and federal regulations governing renewable energy projects. They help ensure that projects comply with environmental, zoning, land use, and other regulatory requirements.</p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Permitting Assistance</h2>
                <p>
                Consultants provide support in obtaining the necessary permits and approvals for renewable energy projects. This includes identifying the permits required, preparing permit applications, and navigating the permitting process with regulatory agencies.
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Environmental Assessments</h2>
                <p>
                Environmental consultants conduct assessments to evaluate the potential environmental impacts of renewable energy projects. This may include environmental impact assessments (EIAs), wildlife studies, habitat assessments, wetland delineations, and cultural heritage assessments.
                </p>
              </div>
            </div>
            <div className="service_page_card_image" data-aos="fade-up" data-aos-duration="1000">
              <img
                className="img-fluid"
                src={PermitsandApporoves}
                alt="permits-and-approvals"
              />
              <div className="text-center">
                <button className="primary-btn " onClick={()=> navigate('/join-now')}>Join now</button>
              </div>
            </div>
            <div className="service_page_card_content">
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Stakeholder Engagement</h2>
                <p>
                Companies offer services to engage with local communities, stakeholders, indigenous groups, and regulatory agencies to address concerns, gather input, and build support for renewable energy projects. 
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Land Use Planning</h2>
                <p>
                Consultants provide expertise in land use planning and zoning regulations to ensure that renewable energy projects comply with local land use policies and regulations. They assist in identifying suitable project sites, navigating zoning approvals, and addressing land use conflicts.
                </p>
              </div>
              <div className="service_page_card_small " data-aos="fade-right" data-aos-duration="1000">
                <h2>Policy Analysis and Advocacy</h2>
                <p>
                Some firms offer services to analyze renewable energy policies and advocate for policy changes to support project development. They provide insights into renewable energy incentives, regulations, and policy trends at the local, state, and federal levels.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
     </div>

       

     
    </div>
  );
};

export default ServicePageCard;
