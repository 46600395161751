
export const Achievements_List = [
    {
  
    title : "600+MW",
    Project : "Liasoning",
    content : "The only limit to our realization of tomorrow will be our doubts of today",
    windproject: false
},
{
  
    title : "200MW",
    Project : "IPP",
    content : "The only limit to our realization of tomorrow will be our doubts of today",
    windproject: false
},
{
  
    title : "100+ Locations",
    Project : "Liasoning",
    content : "The only limit to our realization of tomorrow will be our doubts of today",
    windproject: false
},
{
  
    title : "600+MW",
    Project : "Land Sourcing",
    content : "",
    windproject: true
},
{
  
    title : "300+ MW",
    Project : "Liasoning",
    content : "",
    windproject: true
},
{
  
    title : "50+ MW",
    Project : "Land Sourcing",
    content : "",
    windproject: true
}

]