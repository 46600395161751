import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClockSvg, JobBag, MapSvg } from "../../assets/svg";
import { NOJOBS } from "../../assets/images/index";
import axiosInstance from "../../api/api";
import JobCardLoder from "../Job-card-loder/JobCardLoder";

const JobCard = () => {
  const navigate = useNavigate();
  const containerRef = useRef();
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(1);
  const limit = 2; // Set your limit here
  const [fetching, setFetching] = useState(false);
  const [thresholdValue, setThresholdValue] = useState("1300px")

  const fetchData = async () => {
    setFetching(true);
    try {
      const res = await axiosInstance.get(
        `/jobs/web/list?limit=${limit}&offset=${offset}`
      );
     
      if (res.data.status === false) {
        setHasMore(false);
      }
      if (!res?.data?.status) {
        setHasMore(false);
        return;
      } else {
        const newItems = res?.data?.data;
        setItems((prevItems) => {
          const itemIds = prevItems.map(item => item.id);
          const filteredNewItems = newItems.filter(item => !itemIds.includes(item.id));
          return [...prevItems, ...filteredNewItems];
      });
        setOffset(offset + 1);
      }
    } catch (e) {
      setHasMore(false);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchData();
    if(window.innerWidth < 540){
      setThresholdValue("2500px")
    }
  }, []);

  const applyJob = (id) => {
    navigate(`/careers/apply-now/${id}`);
  };

  const findDays = (data) => {
    const parsedDate = moment(data);
    if (!parsedDate.isValid()) {
      return "Invalid date";
    }

    const now = moment();
    const diffYears = now.diff(parsedDate, "years");
    const diffMonths = now.diff(parsedDate, "months");
    const diffDays = now.diff(parsedDate, "days");
    const diffHours = now.diff(parsedDate, "hours");
    const diffMin = now.diff(parsedDate, "minutes");

    // Determine relative time
    let relativeTime;

    if (diffYears === 1) {
      relativeTime = "1 year ago";
    } else if (diffYears > 1) {
      relativeTime = `${diffYears} years ago`;
    } else if (diffMonths === 1) {
      relativeTime = "1 month ago";
    } else if (diffMonths > 1) {
      relativeTime = `${diffMonths} months ago`;
    } else if (diffDays === 1) {
      relativeTime = "1 day ago";
    } else if (diffDays > 1) {
      relativeTime = `${diffDays} days ago`;
    } else if (diffHours === 1) {
      relativeTime = "one hour ago";
    } else if (diffHours > 1) {
      relativeTime = `${diffHours} hours ago`;
    } else {
      relativeTime = `${diffMin} minutes ago`;
    }
    return relativeTime;
  };
 
    const generateUrl = (name) => {

      const urlFriendlyName = name
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/\s+/g, '-')     
      .replace(/^-+/g, '')      
      .replace(/-+$/g, '');     
     return urlFriendlyName;
    }

return (
    <div>
      <section className="jobs_section">
        {items?.length !== 0 ? (
          <h1 className="text-center">Featured Jobs</h1>
        ) : null}

        <>
          <InfiniteScroll
            dataLength={items?.length}
            next={fetchData}
            hasMore={hasMore}
            scrollThreshold ={thresholdValue}
            loader={
              <JobCardLoder />
            }
           
            ref={containerRef}
          >
            <Row>
              {items &&
                items?.length > 0 &&
                items?.map((item, index) => (
                  <Col lg={6} key={index} className="job_column">
                    <div className="job_card">
                      <div className="job_cont">
                        <div>
                          <h3 className="job_title">{item?.jobTitle}</h3>
                          <div className="job_keys">
                            <div className="job_keys_cont">
                              <ClockSvg />

                              <p> {findDays(item?.createdAt)}</p>
                            </div>
                            <div className="job_keys_cont">
                              <MapSvg />
                              <p>
                                {item?.district?.districtName},{" "}
                                {item?.state?.stateName}
                              </p>
                            </div>
                            <div className="job_keys_cont">
                              <JobBag />
                              <p>{item?.job_shift?.shiftName}</p>
                            </div>
                          </div>
                          <p
                            className="job_description"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                (item?.description).length > 500
                                  ? (item?.description).substring(0, 500) +
                                    `...`
                                  : item?.description
                              }`,
                            }}
                          ></p>
                        </div>
                        <div className="action_buttons">
                          <button
                            className="apply_now_btn"
                            onClick={() => applyJob(item?.id, item?.jobTitle)}
                          >
                            Apply Now
                          </button>
                          <Link
                            to={`/careers/careers-detail/${generateUrl(item?.jobTitle)}/${item?.id}`}
                            className="detail_button ms-2 anchor"
                          >
                            View More Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </InfiniteScroll>
       </>

        {items?.length === 0 ? (
          <div className="no_data_message">
            <img src={NOJOBS} alt="Loading..." />
            <p className='mt-4'>Currently no job openings</p>
          </div>
        ) : null}
      </section>
    
    </div>
  );
};

export default JobCard;
