import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaRegCirclePlay } from "react-icons/fa6";
import { Fancybox } from '@fancyapps/ui';
import { NO_GALLARY } from '../../assets/images';

const EventGallery = ({ viewGallery }) => {
    const [showAll, setShowAll] = useState(false);
    const itemsToShow = showAll ? viewGallery : viewGallery?.slice(0, 8);
  
    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const imageRef = useRef(null);
    const [width, setWidth] = useState();
  
    const updateWidth = () => {
      if (imageRef.current) {
        setWidth(imageRef.current.offsetWidth);
      }
    };
    useEffect(() => {
        Fancybox.bind('[data-fancybox="gallery"]', {
            //
        });
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
          window.removeEventListener('resize', updateWidth);
        };
      }, []);

    return (
        <div>
            <Container>
                <div className='event-gallery'>
                   
                            <h1>Gallery</h1>
                    <Row className='' data-aos="fade-up" data-aos-duration="1000">
                        {
                            itemsToShow?.length > 0 ? (
                                itemsToShow?.map((gallery, index) => (
                                    <Col lg={3} md={6} key={index} ref={imageRef} className="mb-4 hover-effect">
                                        <a data-fancybox="gallery" href={gallery?.imagesUrl}>
                                            {
                                                gallery?.type == "1" ?
                                                    <img class="rounded" style={{height: width}} src={gallery?.imagesUrl} alt='thumbnail-image' />
                                                    : <div className='video_thumb'>
                                                        <img class="rounded" style={{height: width}} src={gallery?.videoThumbnailImagesUrl} alt='video-thumb' />
                                                        <span className='overlay'>
                                                            <FaRegCirclePlay className='play_icon' />
                                                        </span>
                                                    </div>
                                            }
                                        </a>
                                    </Col>
                                ))) : (
                                <div className='no_gallary'>
                                    <div className='no_data_message'>
                                        <img width='70%' src={NO_GALLARY} alt="no gallary image" />
                                        <p>No Gallery Images</p>
                                    </div>
                                </div>
                            )
                        }
                    </Row>

                    {viewGallery?.length > 8 && (
                        <div className="text-center mt-4">
                            <button className="view-more-btn" onClick={toggleShowAll}>
                                {showAll ? (
                                    <>
                                        View Less <IoIosArrowUp className="arrow-icon" />
                                    </>
                                ) : (
                                    <>
                                        View More <IoIosArrowDown className="arrow-icon" />
                                    </>
                                )}
                            </button>
                        </div>
                    )}

                </div>
            </Container >
        </div >
    )
}

export default EventGallery