import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { IoCallOutline } from "react-icons/io5";
import { IoLogoWhatsapp, IoLogoTwitter } from "react-icons/io";
import { FaLinkedinIn } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { FooterLogo } from "../../assets/images";
import { LocationSmall } from "../../assets/svg";

const Footer = () => {
  return (
    <div className="nimalaan-main-section mt-130">
      <Container>
        <Row>
          <Col lg={3}>
            <img className="footer-logo" src={FooterLogo} alt="footer-logo" />
          </Col>
          <Col className="footer_order" lg={2}>
            <div className="links">
              <h4 className="links_head">Links</h4>
              <div className="mt-30">
                <Link to={"/services"}>Services</Link>
                <Link to={"/about"}>About </Link>
                <Link to={"/clients"}>Clients</Link>
                <Link to={"/achievements"}>Achievements</Link>
                <Link to={"/mission"}>Mission</Link>
                <Link to={"/careers"}>Careers</Link>
                <Link to={"/events"}>Events</Link>
                <Link to={"/contact-us"}>Contact</Link>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <div className="contact">
              <div className="address_box">
                <LocationSmall/>
                <div>
                  <p>
                    No 43B, Rajarajeshwari Nagar, Madipakkam, <br /> Chennai 600
                    091
                  </p>
                </div>
              </div>
              <div className="address_box mt-4">
                <IoCallOutline fontSize={90} style={{color:'',height:'30px'}} />
                <div>
                  <p>
                    <a href="mailto:nimalaanenergiesllp@gmail.com " target="blank">
                      nimalaanenergiesllp<wbr />@gmail.com{" "}
                    </a>
                  </p>
                  <p>
                    <a href="tel:+919444444832" target="blank">94444 44832 </a> |{" "}
                    <a href="tel:+919841923999" target="blank"> 98419 23999</a>
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3}>
            <div className="social-media">
              <h4 className="links_head">Follow Us</h4>
              <div className="social_links">
                <a target="blank" href={"https://api.whatsapp.com/send?phone=9444444832"}>
                  <IoLogoWhatsapp />
                </a>
                <a target="blank" href={"https://www.linkedin.com"}>
                  <FaLinkedinIn />
                </a>
                <a target="blank" href={"https://www.twitter.com"}>
                  <IoLogoTwitter />
                </a>
                <a target="blank" href={"https://www.instagram.com"}>
                  <AiFillInstagram />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <div className="copy-rights">
          <p>Copyright © 2024 Nimalaan</p>
          <p> <a  href="https://www.technogenesis.in/" target="blank">Developed by Technogenesis</a> </p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
