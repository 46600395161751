import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { IMG_PLACEHOLDER } from '../../assets/images'

const EventCardLoader = () => {
    return (
        <div>
            <Row>
                <Col lg={4} md={6} className='job_column' >
                    <div className='event_card'>
                        {/* <img className='event-img' src={IMG_PLACEHOLDER} alt="event-img-placeholder" />  */}
                        <div className='event_cont'>
                            <SkeletonTheme>
                                <Skeleton count={2} borderRadius={8} style={{ height: "24px" }} />
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "16px", width: "60%", marginTop: "20px" }}
                                />
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "16px", width: "50%", marginTop: "1    0px" }}
                                />
                                <div className="mt-1">
                                    <Skeleton
                                        count={1}
                                        borderRadius={8}
                                        style={{ height: "14px", width: "90%" }}
                                    />
                                    <Skeleton
                                        count={1}
                                        borderRadius={8}
                                        style={{ height: "14px", width: "80%" }}
                                    />
                                </div>
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "20px", width: "40%", marginTop: "20px" }}
                                />
                            </SkeletonTheme>
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={6} className='job_column' >
                    <div className='event_card'>
                        {/* <img className='event-img' src={IMG_PLACEHOLDER} alt="event-img-placeholder" /> */}
                        <div className='event_cont'>
                            <SkeletonTheme>
                                <Skeleton count={2} borderRadius={8} style={{ height: "24px" }} />
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "16px", width: "60%", marginTop: "20px" }}
                                />
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "16px", width: "50%", marginTop: "1    0px" }}
                                />
                                <div className="mt-1">
                                    <Skeleton
                                        count={1}
                                        borderRadius={8}
                                        style={{ height: "14px", width: "90%" }}
                                    />
                                    <Skeleton
                                        count={1}
                                        borderRadius={8}
                                        style={{ height: "14px", width: "80%" }}
                                    />
                                </div>
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "20px", width: "40%", marginTop: "20px" }}
                                />
                            </SkeletonTheme>
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={6} className='job_column' >
                    <div className='event_card'>
                        {/* <img className='event-img' src={IMG_PLACEHOLDER} alt="event-img-placeholder" /> */}
                        <div className='event_cont'>
                            <SkeletonTheme>
                                <Skeleton count={2} borderRadius={8} style={{ height: "24px" }} />
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "16px", width: "60%", marginTop: "20px" }}
                                />
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "16px", width: "50%", marginTop: "1    0px" }}
                                />
                                <div className="mt-1">
                                    <Skeleton
                                        count={1}
                                        borderRadius={8}
                                        style={{ height: "14px", width: "90%" }}
                                    />
                                    <Skeleton
                                        count={1}
                                        borderRadius={8}
                                        style={{ height: "14px", width: "80%" }}
                                    />
                                </div>
                                <Skeleton
                                    count={1}
                                    borderRadius={8}
                                    style={{ height: "20px", width: "40%", marginTop: "20px" }}
                                />
                            </SkeletonTheme>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default EventCardLoader