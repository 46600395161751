import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { Empowering, Empowering1 } from "../../assets/images";


const OurMission = () => {
  const navigate = useNavigate()
  return (
    <div className="nimalaan-main-section position-relative o pt-75 pb-75">
      <div className="color-shade"></div>
      <Container>
        <Row>
          <Col lg={6} data-aos="fade-right" data-aos-duration="1000">
            <Row className="ourmission-row">
              <Col xs={6} md={{ span: 4, offset: 2 }} lg={5}>
                <img
                  className="img-fluid ourservie_img w-100"
                  src={Empowering}
                  alt="empowering-a-sustainable-world-1"
                />
              </Col>
              <Col xs={6} md={{ span: 4 }} lg={5}>
                <img
                  className="img-fluid ourservie_img w-100"
                  src={Empowering1}
                  alt="empowering-a-sustainable-world-2"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={5} data-aos="fade-left" data-aos-duration="1000">
            <div className="our-mission pl-40">
              <h4 className="image-card-title">Our Mission</h4>
              <h2 className="image-card-main">
                Empowering a sustainable world, one watt at a time{" "}
              </h2>
              <p className="image-card-content">
                We are committed to providing exceptional customer service and
                delivering high-quality results. We understand the unique
                challenges of wind and solar energy projects, and we work
                closely with our clients to ensure their projects are completed
                on time, within budget, and to the highest standards.
              </p>

              <button className="primary-btn mt-4" onClick={()=>navigate('/join-now')}>Join now</button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurMission;
