import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AboutTech = () => {
  return (
    <div className="nimalaan-main-section mt-50" data-aos="fade-down" data-aos-duration="1000">
      <h2 className="nimalaan-section-title">
        We are Nimalaan Energies – a global leader in energy technology
      </h2>
      <div className="innovation-content">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <p className="mt-1 text-center text-information">
                Energy transition is the greatest challenge our generation
                faces. How do we reduce emissions while also increase energy
                supply? It is an uphill battle. And there is no silver bullet.
                But finding solutions has always been in our DNA. For more than
                150 years our engineers have been spearheading the
                electrification of the world. Today we are a team of
                97.000 sharing the same passion, vision and values. Our
                diversity makes us strong and helps us to find answers together
                with our partners.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <p className="mt-4 text-center text-information">
                Located in 90 countries, Siemens Energy operates across the
                whole energy landscape. From conventional to renewable power,
                from grid technology to storage to electrifying complex
                industrial processes.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <p className="mt-4 text-center text-information">
                Our mission is to support companies and countries with what they
                need to reduce greenhouse gas emissions and make energy
                reliable, affordable, and more sustainable. Let’s energize
                society.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AboutTech;
