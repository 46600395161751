import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  CeoImage,
  CoFounderImage,
  VicePresidentImage,
  ManagerImage,
} from "../../assets/images";
import "./OurTeam.css";

const OurTeam = () => {
  const profile = [
    {
      name: "Saurabh Singh",
      img: CeoImage,
      designation: "CEO & DIRECTOR",
    },
    {
      name: "Prateek Saxena",
      img: CoFounderImage,
      designation: "DIRECTOR & CO-FOUNDER",
    },
    {
      name: "Rajat Singh",
      img: VicePresidentImage,
      designation: "VP- SERVICE DELIVERY",
    },
    {
      name: "Apeksha Mehta",
      img: ManagerImage,
      designation: "MANAGER TECHNOLOGY",
    },
  ];
  return (
    <div className="nimalaan-main-section mt-10">
      <h2 className="nimalaan-section-title" data-aos="fade-up" data-aos-duration="300">Our Core Team Leaders</h2>
      <div className="innovation-content">
        <Container>
          <Row data-aos="fade-right" data-aos-duration="1000">
            <Col lg={{span:8, offset:2}}>
              <p className="mt-1 text-center text-information">
                Our work is often recognized by prestigious organizations as
                innovative, functional, and exceptionally
                 aesthetic. We are proud to share some of our recent
                industry awards.
              </p>
            </Col>
          </Row>
          <Row className="mt-45" data-aos="fade-right" data-aos-duration="1000">
            
              {profile.map((item, index) => (
                <Col xs={6} md={3} lg={3}>
                  <div className="our-team-card text-cente">
                    <img
                      className="img-fluid"
                      src={item?.img}
                      alt={item?.name}
                    />
                    <div className="our-team-card-content ">
                      <h3>{item?.name}</h3>
                      <p>{item?.designation}</p>
                    </div>
                  </div>
                </Col>
              ))}
            
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default OurTeam;
