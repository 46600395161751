import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { WhatWeDoImg } from "../../assets/images";

const WhatWeDo = () => {
  const firstRow = [
    {
      subtitle: "Development",
      content:
        "Building meaningful relationships with local stakeholders to develop successful projects.",
    },
    {
      subtitle: "Construction",
      content:
        "Using cutting-edge technology and people expertise to deliver high quality construction projects.",
    },
    {
      subtitle: "Support services",
      content:
        "Our expert team fully optimise and maintain your assets, maximising value and protecting revenue.",
    },
    {
      subtitle: "Digital solutions",
      content:
        "Using AI and machine learning to find new ways to drive positive for outcomes.",
    },
  ];

  return (
    <div className="nimalaan-main-section card_sec mt-130">
      <Container>
        <Row>
          <Col md={5} data-aos="fade-right" data-aos-duration="1000">
            <img
              className="img-fluid service"
              src={WhatWeDoImg}
              alt="what we do"
            />
          </Col>

          <Col  md={7} data-aos="fade-right" data-aos-duration="1000">
            <div className="our-achievements">
              <h2 className="image-content-card">What We Do</h2>
              <p className="image-card-content">
                WRI is a trusted partner for change. Using research-based
                approaches, we work 
                globally and in focus countries to meet people’s essential
                needs; to protect and restore
                 nature; and to stabilize the climate and build more
                resilient communities. We aim to
                 fundamentally transform the way the world produces food,
                uses energy and designs its
                cities to create a better future for all. We work across
                several topics affecting people,
                nature and the climate:
              </p>

              <Row className="mt-4">
                {firstRow.map((item, index) => {
                  return (
                    <Col key={item?.title} md={6} lg={6} className="mb-2">
                      <div className="whatwedo_card_about">
                        <h3 className="achievement_card_title">{item?.title}</h3>
                        <h4 className="achievement_card_subtitle">
                          {item?.subtitle}
                        </h4>
                        <p className="achievement_card_content">
                          {item?.content}
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhatWeDo;