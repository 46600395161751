import React from 'react'
import { NotFundImg } from '../../assets/images'
import './notfound.css'

const NotFound = () => {
  return (
    <div className='nimaalan-not-found' >

        <img src={NotFundImg} alt="404 not found" />


    </div>
  )
}

export default NotFound