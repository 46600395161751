import React from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import './Clients-List.css';
const ClientsList = ({ data }) => {
    return (
        <Container>
            <Row>
                {data.map((client, index) => (
                    <React.Fragment key={index}>
                        <Col lg={12} className='mt-5'>
                            <Card className="p-4 client-card">
                                <Row className={index % 2 === 0 ? '' : 'd-flex flex-wrap-reverse'}>
                                    <Col lg={index % 2 === 0 ? 4 : 6} data-aos="fade-right" data-aos-duration="1000">
                                        {index % 2 === 0 ? (
                                            <Card.Img src={client.image} alt={client.alt} className='client-card-image' />) : (
                                            <Card.Body className='mt-3'>
                                                <Card.Title className='client-card-title'>{client.title}</Card.Title>
                                                <Card.Text className='client-card-content mt-3'>{client.content}</Card.Text>
                                            </Card.Body> )}
                                    </Col>
                                    {index % 2 === 0 ? (
                                            <Col lg={6} data-aos="fade-right" data-aos-duration="1000">
                                                <Card.Body className='mt-3 ml-30'>
                                                    <Card.Title className='client-card-title'>{client.title}</Card.Title>
                                                    <Card.Text className='client-card-content mt-3'>{client.content}</Card.Text>
                                                </Card.Body>
                                            </Col>) : (<>
                                        <Col lg={2}></Col>
                                        <Col lg={4} data-aos="fade-right" data-aos-duration="1000">
                                            <Card.Img src={client.image} alt={client.alt} className='client-card-image' />
                                        </Col> </>
                                    )}
                                </Row>
                            </Card>
                        </Col>
                    </React.Fragment>
                ))}
            </Row>
        </Container>
    );
}
export default ClientsList;